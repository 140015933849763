<div class="container">
  <h1 class="line-background"><span>TRECHOS</span></h1>
  <form [formGroup]="formDocument" #formDir="ngForm">
    <div class="row">
      <div class="col">
        <div class="row autocomplete">
          <span class="material-icons">
            flight_takeoff
          </span>
          <div class="label autocomplete">Origem*</div>
        </div>
        <mat-form-field>
          <mat-select
            [formControl]="countryOriCtrl"
            [(value)]="originSelected"
            id="origem"
            (selectionChange)="selectOrigin($event)"
            formControlName="originSelectedForm"
            [ngClass]="originSelectedForm.invalid && submitted ? 'danger' : ''"
          >
            <mat-option>
              <ngx-mat-select-search
                [placeholderLabel]="''"
                [noEntriesFoundLabel]="''"
                [formControl]="countryOriFilterCtrl"
              ></ngx-mat-select-search>
            </mat-option>
            <mat-option
              *ngFor="let origin of filteredOriCountrys | async"
              [value]="origin"
            >
              {{ origin.nome }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col">
        <div class="row autocomplete">
          <span class="material-icons">
            flight_land
          </span>
          <div class="label autocomplete">Destino*</div>
        </div>
        <mat-form-field>
          <mat-select
            [formControl]="countryDesCtrl"
            [(value)]="destinySelected"
            id="destino"
            [disabled]="!originSelected"
            (selectionChange)="selectDestiny($event)"
            formControlName="destinySelectedForm"
            [ngClass]="destinySelectedForm.invalid && submitted ? 'danger' : ''"
          >
            <mat-option>
              <ngx-mat-select-search
                [placeholderLabel]="''"
                [noEntriesFoundLabel]="''"
                [formControl]="countryDesFilterCtrl"
              ></ngx-mat-select-search>
            </mat-option>
            <mat-option
              *ngFor="let destino of filteredDesCountrys | async"
              [value]="destino"
            >
              {{ destino.nome }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div>
      <mat-tab-group mat-align-tabs="center">
        <mat-tab label="Português">
          <h2>Turismo</h2>
          <angular-editor
            id="turismoPt"
            [config]="editorConfig"
            [(ngModel)]="document.descTurismoPt"
            formControlName="descTurismoPt"
          ></angular-editor>
          <h2>Residência Temporária</h2>
          <angular-editor
            id="residenciaTemPt"
            [config]="editorConfig"
            [(ngModel)]="document.descResiTempPt"
            formControlName="descResiTempPt"
          ></angular-editor>
          <h2>Residência Fixa</h2>
          <angular-editor
            id="residenciaFixaPt"
            [config]="editorConfig"
            [(ngModel)]="document.descResiFixaPt"
            formControlName="descResiFixaPt"
          ></angular-editor>
          <h2>Embaixada(s) e/ou Consulado(s)</h2>
          <angular-editor
            id="embaixaPt"
            [config]="editorConfig"
            [(ngModel)]="document.embaixadaPt"
            formControlName="embaixadaPt"
          ></angular-editor>
        </mat-tab>
        <mat-tab label="Inglês">
          <h2>Turismo</h2>
          <angular-editor
            id="turismoEn"
            [config]="editorConfig"
            [(ngModel)]="document.descTurismoEn"
            formControlName="descTurismoEn"
          ></angular-editor>
          <h2>Residência Temporária</h2>
          <angular-editor
            id="residenciaTemEn"
            [config]="editorConfig"
            [(ngModel)]="document.descResiTempEn"
            formControlName="descResiTempEn"
          ></angular-editor>
          <h2>Residência Fixa</h2>
          <angular-editor
            id="residenciaFixaEn"
            [config]="editorConfig"
            [(ngModel)]="document.descResiFixaEn"
            formControlName="descResiFixaEn"
          ></angular-editor>
          <h2>Embaixada(s) e/ou Consulado(s)</h2>
          <angular-editor
            id="embaixaEn"
            [config]="editorConfig"
            [(ngModel)]="document.embaixadaEn"
            formControlName="embaixadaEn"
          ></angular-editor>
        </mat-tab>
        <mat-tab label="Espanhol">
          <h2>Turismo</h2>
          <angular-editor
            id="turismoEs"
            [config]="editorConfig"
            [(ngModel)]="document.descTurismoEs"
            formControlName="descTurismoEs"
          ></angular-editor>
          <h2>Residência Temporária</h2>
          <angular-editor
            id="residenciaTemEs"
            [config]="editorConfig"
            [(ngModel)]="document.descResiTempEs"
            formControlName="descResiTempEs"
          ></angular-editor>
          <h2>Residência Fixa</h2>
          <angular-editor
            id="residenciaFixaEs"
            [config]="editorConfig"
            [(ngModel)]="document.descResiFixaEs"
            formControlName="descResiFixaEs"
          ></angular-editor>
          <h2>Embaixada(s) e/ou Consulado(s)</h2>
          <angular-editor
            id="embaixaEs"
            [config]="editorConfig"
            [(ngModel)]="document.embaixadaEs"
            formControlName="embaixadaEs"
          ></angular-editor>
        </mat-tab>
      </mat-tab-group>
      <br />
      <div class="row">
        <div class="col-sm left botton"></div>
        <div class="col-sm right">
          <button type="submit" class="btn btn-primary" (click)="save()">
            SALVAR
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
