import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthUtil } from 'src/app/utils/auth';
import { Router } from '@angular/router';
import { ConstantsUrl } from 'src/app/utils/constants-url';
import { Retorno } from 'src/app/model/retorno';
import { Functions } from 'src/app/utils/functions';
import { MatDialog } from '@angular/material/dialog';
import { LoadComponent } from 'src/app/component/load/load.component';
import { Login } from 'src/app/model/login';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private clientId = 'cliente-questoes-legais';
  private key = 'e8b8c6bc-e058-4fc2-9799-4e19b9cbd73d';
  public redirectUri = 'http://localhost:8080/';
  private userApp = 'SistemaCliente';
  private password = 'Cl1entee3';
  private idioma: string = 'pt';
  public usuarioSenha: string;
  public sessao: string;

  constructor(
    private _http: HttpClient,
    private _router: Router,
    public dialog: MatDialog
  ) {
    this.idioma = localStorage.getItem('idioma');
    this._idioma();
  }

  obtainAccessToken(login: Login): Promise<any> {
    this._loading();
    return new Promise((resolve, reject) => {
      const email = login ? login.usuario : this.userApp;
      const senha = login ? login.senha : this.password;
      let params = new URLSearchParams();
      params.append('grant_type', 'password');
      params.append('username', email);
      params.append('password', Functions.simpleCode(senha));
      let headers = new HttpHeaders({
        Authorization: 'Basic ' + btoa(`${this.clientId}:${this.key}`),
        'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      this._http
        .post(`${ConstantsUrl.BASE}/oauth/token`, params.toString(), {
          headers: headers,
        })
        .subscribe(
          (data) => {
            this.saveToken(data, login);
            this._close();
            resolve();
          },
          (err) => {
            this.saveToken(null, login);
            this._close();
            reject(err);
          }
        );
    });
  }
  saveToken(token, login) {
    if (login) {
      AuthUtil.setLogin(login.usuario);
    }
    if (token) {
      var expireDate = new Date().getTime() + 1000 * token.expires_in;
      AuthUtil.setToken(token.access_token, expireDate);
    } else {
      AuthUtil.setToken(token, token);
    }
  }
  getResource(resourceUrl: string, params?: HttpParams, hideLoad?: boolean): Promise<Retorno> {
    if (hideLoad && hideLoad === true) {
      this._close();
    } else {
      this._loading();
    }
    return new Promise<Retorno>((resolve, reject) => {
      this._http
        .get<Retorno>(`${ConstantsUrl.BASE}${resourceUrl}`, {
          headers: AuthUtil.getHeader(),
          params: params,
        })
        .subscribe(
          (data) => {
            this._close();
            resolve(data);
          },
          (erro) => {
            this._error(erro, reject);
          }
        );
    });
  }
  putResource(resourceUrl: string, entity: any): Promise<Retorno> {
    this._loading();
    return new Promise<Retorno>((resolve, reject) => {
      this._http
        .put<Retorno>(`${ConstantsUrl.BASE}${resourceUrl}`, entity, {
          headers: AuthUtil.getHeader(),
        })
        .subscribe(
          (data) => {
            this._close();
            resolve(data);
          },
          (erro) => {
            this._error(erro, reject);
          }
        );
    });
  }
  postResource(resourceUrl: string, entity: any): Promise<Retorno> {
    this._loading();
    return new Promise<Retorno>((resolve, reject) => {
      this._http
        .post<Retorno>(`${ConstantsUrl.BASE}${resourceUrl}`, entity, {
          headers: AuthUtil.getHeader(),
        })
        .subscribe(
          (data) => {
            this._close();
            resolve(data);
          },
          (erro) => {
            this._error(erro, reject);
          }
        );
    });
  }
  logout() {
    this._loading();
    this._http
      .get(`${ConstantsUrl.BASE}/usuario/logout`, {
        headers: AuthUtil.getHeader(),
      })
      .subscribe(
        (data) => {
          this._close();
          AuthUtil.logout();
          this._router.navigate(['painel/login']);
        },
        (err) => {
          this._close();
          AuthUtil.logout();
          this._router.navigate(['painel/login']);
        }
      );
  }
  private _loading() {
    this.dialog.open(LoadComponent, { panelClass: 'my-load' });
  }
  private _close() {
    this.dialog.closeAll();
  }
  private _error(erro, reject) {
    this._close();
    if (erro && (erro.status === 401 || erro.status === 402)) {
      let retorno = new Retorno();
      retorno.mensagem =
        erro.status === 401
          ? this.sessao
          : this.usuarioSenha;
      retorno.error = true;
      reject(retorno);
      this._router.navigate(['painel/login']);
    } else if (erro.error) {
      reject(erro.error);
    } else {
      let retorno = new Retorno();
      retorno.mensagem = 'Error';
      retorno.error = true;
      reject(retorno);
    }
  }
  private _idioma(){
    if(this.idioma === "en"){
      this.usuarioSenha = 'Invalid user and / or password (s)';
      this.sessao = 'Session Expired';
    }else if(this.idioma === "es"){
      this.usuarioSenha = 'Usuario y / o contrase\u00f1a no válidos';
      this.sessao = 'Sesión Expirada';
    }else{
      this.usuarioSenha = 'Usu\u00e1rio e/ou Senha inv\u00e1lido(s)';
      this.sessao = 'Sessão Expirada';
    }
  }
}
