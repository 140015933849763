import { Injectable } from '@angular/core';
import { Retorno } from 'src/app/model/retorno';
import { AuthService } from '../auth/auth.service';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class StretchService {
  private stretchPath = '/trecho';
  constructor(private _auth: AuthService) {}

  getStretch(origin: number, destiny: number, idioma?: string, hideLoad?: boolean): Promise<Retorno> {
    const idiom = idioma !== undefined ? idioma : '';
    let params = new HttpParams().set('idioma', `${idiom}`);
    return this._auth.getResource(`${this.stretchPath}/${origin}/${destiny}`, params, hideLoad);
  }
}
