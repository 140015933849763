import { Destino } from './destino';

export class Documento {
  id: number;
  descTurismoPt: string;
  descResiTempPt: string;
  descResiFixaPt: string;
  embaixadaPt: string;
  descTurismoEn: string;
  descResiTempEn: string;
  descResiFixaEn: string;
  embaixadaEn: string;
  descTurismoEs: string;
  descResiTempEs: string;
  descResiFixaEs: string;
  embaixadaEs: string;
  descTurismo: string;
  descResiTemp: string;
  descResiFixa: string;
  embaixada: string;
  destino: Destino;
}
