import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rodape-painel',
  templateUrl: './rodape-painel.component.html',
  styleUrls: ['./rodape-painel.component.scss']
})
export class RodapePainelComponent implements OnInit {

  public data = new Date();

  constructor() { }

  ngOnInit(): void {
  }

}
