import { DialogComponent } from 'src/app/component/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Retorno } from '../model/retorno';

export class Functions {
  static openDialog(dialog: MatDialog, retorno: Retorno, closeHandler?) {
    const dialogRef = dialog.open(DialogComponent, {
      width: '300px',
      data: retorno,
    });
    if (closeHandler) {
      dialogRef.afterClosed().subscribe(() => {
        closeHandler();
      });
    }
  }
  static simpleCode(value: string): string {
    let retorno = [];
    for (let index = 0; index < value.length; index++) {
      retorno.push(value.charCodeAt(index) + 5);
    }
    return String.fromCharCode(...retorno);
  }
  static number(value): number {
    const retorno = this.removeMask(value);
    if (retorno) {
      return parseInt(retorno);
    }
    return null;
  }
  static removeMask(value: string): string {
    if (value) {
      return value.replace(/[^0-9]+/g, '');
    }
    return null;
  }
  static createMaskCel(value) {
    if (value) {
      return value.replace(/(\d{3})(\d{5})(\d{4})/, '($1) $2-$3');
    }
  }
  static createMaskPhone(value) {
    if (value) {
      return value.replace(/(\d{3})(\d{4})(\d{4})/, '($1) $2-$3');
    }
  }
  static createMaskCpf(value) {
    if (value) {
      return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    }
  }
  static createMaskCnpj(value) {
    if (value) {
      return value.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        '$1.$2.$3/$4-$5'
      );
    }
  }
  static somenteNumero(value) {
    return value.replace(/\D/gim, '');
  }
  static zeroLeft(number, sizeMax): string {
    var retorno = `${number}`;
    if (number) {
      while (retorno.length < sizeMax) {
        retorno = `0${retorno}`;
      }
    }
    return retorno;
  }

  static randomArray(array) {
    var indice_atual = array.length,
      valor_temporario,
      indice_aleatorio;

    while (0 !== indice_atual) {
      indice_aleatorio = Math.floor(Math.random() * indice_atual);
      indice_atual -= 1;

      valor_temporario = array[indice_atual];
      array[indice_atual] = array[indice_aleatorio];
      array[indice_aleatorio] = valor_temporario;
    }

    return array;
  }

  static isNumber(valor) {
    return isNaN(valor);
  }
}
