import { Component, OnInit, ViewChild } from '@angular/core';
import { Functions } from 'src/app/utils/functions';
import { Pais } from 'src/app/model/pais';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { AttendanceService } from 'src/app/service/attendance/attendance.service';
import { MatDialog } from '@angular/material/dialog';
import { CountryService } from 'src/app/service/country/country.service';
import { Usuario } from 'src/app/model/usuario';
import { AuthUtil } from 'src/app/utils/auth';
import { UserService } from 'src/app/service/user/user.service';
import { takeUntil, take } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';
import { AtendimentoEspecialistaSimples } from 'src/app/model/atendimento-especialista-simples';

@Component({
  selector: 'app-stretch',
  templateUrl: './stretch.component.html',
  styleUrls: ['./stretch.component.scss'],
})
export class StretchComponent implements OnInit {
  protected _onDestroy = new Subject<void>();
  protected _onDestroyDes = new Subject<void>();
  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
  @ViewChild('singleSelect', { static: true }) singleSelectDes: MatSelect;
  public originSelected: Pais;
  public destinySelected: Pais;
  public countrysOri: Pais[];
  public countrysDest: Pais[];
  public idioma: string = 'pt';
  public emailLogged;
  public user: Usuario;
  public isBr: boolean;
  public isCartao: boolean = true;
  public urlTransacao: string;

  //Origem
  public countryOriCtrl: FormControl = new FormControl();
  public countryOriFilterCtrl: FormControl = new FormControl();
  public filteredOriCountrys: ReplaySubject<Pais[]> = new ReplaySubject<Pais[]>(
    1
  );
  //Destino
  public countryDesCtrl: FormControl = new FormControl();
  public countryDesFilterCtrl: FormControl = new FormControl();
  public filteredDesCountrys: ReplaySubject<Pais[]> = new ReplaySubject<Pais[]>(
    1
  );

  constructor(
    private _countryService: CountryService,
    private _attendance: AttendanceService,
    private _userService: UserService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.emailLogged = AuthUtil.getLogged();
    this.idioma = localStorage.getItem('idioma');
    this.originSelected = null;
    this.destinySelected = null;
    this.countrysOri = [];
    this.countrysDest = null;
    this._init();
  }
  private async _init() {
    this._getUser();
    await this._getCountys();
  }
  private async _getUser() {
    try {
      const retorno = await this._userService.getUser(this.emailLogged);
      this.user = retorno.entidade;
      this.isBr = this.user.brasileiro;
    } catch (error) {
      Functions.openDialog(this.dialog, error);
    }
  }
  selectOrigin(event) {
    if (this.originSelected) {
      this.destinySelected = null;
      this._getCountys(this.originSelected.id);
    } else {
      this.destinySelected = null;
    }
  }
  selectDestiny(event) {
    this._checkout();
  }
  private async _getCountys(id?: number) {
    try {
      const retorno = await this._countryService.getCounty(
        this.idioma,
        id,
        true
      );
      if (id) {
        this.countrysDest = retorno.lista;
        this._initiCountryDes();
      } else {
        this.countrysOri = retorno.lista;
        this._initiCountryOri();
      }
    } catch (error) {
      Functions.openDialog(this.dialog, error);
    }
  }
  //Origem
  private _initiCountryOri() {
    this.filteredOriCountrys.next(this.countrysOri.slice());
    this.countryOriFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filtercountrys();
      });
    this.setInitialValue();
  }
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
    this._onDestroyDes.next();
    this._onDestroyDes.complete();
  }
  protected setInitialValue() {
    this.filteredOriCountrys
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        if (this.singleSelect) {
          this.singleSelect.compareWith = (a: Pais, b: Pais) =>
            a && b && a.id === b.id;
        }
      });
  }
  protected filtercountrys() {
    if (!this.countrysOri) {
      return;
    }
    let search = this.countryOriFilterCtrl.value;
    if (!search) {
      this.filteredOriCountrys.next(this.countrysOri.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredOriCountrys.next(
      this.countrysOri.filter(
        (country) => country.nome.toLowerCase().indexOf(search) > -1
      )
    );
  }
  //Destino
  private _initiCountryDes() {
    this.filteredDesCountrys.next(this.countrysDest.slice());
    this.countryDesFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroyDes))
      .subscribe(() => {
        this.filterDesCountrys();
      });
    this.setInitialValueDes();
  }
  protected setInitialValueDes() {
    this.filteredDesCountrys
      .pipe(take(1), takeUntil(this._onDestroyDes))
      .subscribe(() => {
        if (this.singleSelectDes) {
          this.singleSelectDes.compareWith = (a: Pais, b: Pais) =>
            a && b && a.id === b.id;
        }
      });
  }
  protected filterDesCountrys() {
    if (!this.countrysDest) {
      return;
    }
    let search = this.countryDesFilterCtrl.value;
    if (!search) {
      this.filteredDesCountrys.next(this.countrysDest.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredDesCountrys.next(
      this.countrysDest.filter(
        (country) => country.nome.toLowerCase().indexOf(search) > -1
      )
    );
  }
  private async _checkout() {
    try {
      let atd: AtendimentoEspecialistaSimples = new AtendimentoEspecialistaSimples();
      atd.especialista = this.user.id;
      atd.pOrigem = this.originSelected.id.toString();
      atd.pDestino = this.destinySelected.id.toString();
      atd.idioma = this.idioma;
      atd.boleto = !this.isCartao;
      console.log(JSON.stringify(atd));
      const retorno = await this._attendance.insert(atd);
      this.urlTransacao = retorno.entidade.cdTransacao;
    } catch (error) {
      Functions.openDialog(this.dialog, error);
    }
  }
}
