<div class="container">
    <h1 class="line-background"><span>ADMINISTRADOR</span></h1>
    <div class="row">
        <div class="col-xl-3 .col-lg-3 .col-md-3 .col-sm-0 .col-0"></div>
        <div class="col-xl-6 .col-lg-6 .col-md-6 .col-sm-12 .col-12 frame">
            <fieldset class="form-group">
                <label for="nome" class="bmd-label-static">Nome</label>
                <input type="text" id="nome" class="form-control" [(ngModel)]="user.nome" disabled />
            </fieldset>
            <fieldset class="form-group">
                <label for="nome" class="bmd-label-static">e-mail</label>
                <input type="email" id="email" class="form-control" placeholder="e-mail" [(ngModel)]="user.email"
                    disabled />
            </fieldset>
            <div class="form-group">
                <label for="status" class="bmd-label-static">Ativo</label>
                <label class="bmd-label-static checkbox" for="status">
                    <input type="checkbox" value="" id="status" [(ngModel)]="user.ativo">
                </label>
            </div>
            <br />
            <br />
            <div class="row">
                <div class="col-sm left botton"></div>
                <div class="col-sm right">
                    <button class="btn btn-secondary" (click)="back()">
                        VOLTAR
                    </button>
                    &nbsp;
                    <button *ngIf="podeAlterar" type="submit" class="btn btn-primary" (click)="update()">
                        ALTERAR
                    </button>
                </div>
            </div>
        </div>
        <div class="col-xl-3 .col-lg-3 .col-md-3 .col-sm-0 .col-0"></div>
    </div>
</div>