import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Mensagem } from 'src/app/model/mensagem';
import { Retorno } from 'src/app/model/retorno';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  private messagePath = '/mensagem';
  private messageSendPath = '/mensagem/enviar';
  constructor(private _auth: AuthService) { }

  sender(mensagem: Mensagem): Promise<Retorno> {
    return this._auth.postResource(`${this.messageSendPath}`, mensagem);
  }
  getFromEmail(email: string, page: number, page_size: number): Promise<Retorno> {
    let params = new HttpParams().set('page', `${page}`).set('page_size', `${page_size}`);
    return this._auth.getResource(`${this.messagePath}/${email}`, params);
  }
}
