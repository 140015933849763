<div class="topo-line"></div>
<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarNavAltMarkup"
    aria-controls="navbarNavAltMarkup"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
    <div class="navbar-nav">
      <a
        class="nav-item nav-link"
        routerLink="/painel"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        data-toggle="collapse"
        data-target=".navbar-collapse.show"
        >Início
      </a>
      <a
        class="nav-item nav-link"
        routerLink="/painel/country"
        routerLinkActive="active"
        data-toggle="collapse"
        data-target=".navbar-collapse.show"
        >Países
      </a>
      <a
        class="nav-item nav-link"
        routerLink="/painel/information"
        routerLinkActive="active"
        data-toggle="collapse"
        data-target=".navbar-collapse.show"
        >Trechos
      </a>
      <a
        class="nav-item nav-link"
        routerLink="/painel/user"
        routerLinkActive="active"
        data-toggle="collapse"
        data-target=".navbar-collapse.show"
        >Usuário
      </a>
      <a
        class="nav-item nav-link"
        routerLink="/painel/profiles"
        routerLinkActive="active"
        data-toggle="collapse"
        data-target=".navbar-collapse.show"
        >Perfis
      </a>
    </div>
  </div>
  <button class="btn btn-link" (click)="onLogout()">Sair</button>
</nav>
<app-title></app-title>
<router-outlet></router-outlet>
<app-rodape-painel></app-rodape-painel>
