import { Component, OnInit, ViewChild } from '@angular/core';
import { Subject, ReplaySubject } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { Pais } from 'src/app/model/pais';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Usuario } from 'src/app/model/usuario';
import { CountryService } from 'src/app/service/country/country.service';
import { MatDialog } from '@angular/material/dialog';
import { Functions } from 'src/app/utils/functions';
import { take, takeUntil } from 'rxjs/operators';
import { Endereco } from 'src/app/model/endereco';
import { UserService } from 'src/app/service/user/user.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth/auth.service';
import { AuthUtil } from 'src/app/utils/auth';
import CelMask from 'src/app/model/mask/cel';
import PhoneMask from 'src/app/model/mask/phone';
import CpfMask from 'src/app/model/mask/cpf';
import CnpjMask from 'src/app/model/mask/cnpj';
import CodinMask from 'src/app/model/mask/codin';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  public user: Usuario;
  public paisSelected: Pais;
  public isCpf: boolean = true;
  public isBr: boolean;
  public idioma: string = 'pt';
  public countrys: Pais[];
  public formReg;
  public submitted;

  protected _onDestroy = new Subject<void>();
  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
  public countryCtrl: FormControl = new FormControl();
  public countryFilterCtrl: FormControl = new FormControl();
  public filteredCountrys: ReplaySubject<Pais[]> = new ReplaySubject<Pais[]>(1);
  public disabled: boolean = false;
  public emailLogged;
  public edicao;
  public celMask = new CelMask();
  public phoneMask = new PhoneMask();
  public cpfMask = new CpfMask();
  public cnpjMask = new CnpjMask();
  public codinMask = new CodinMask();
  public cpfField: string;
  public cnpjField: string;
  public phoneField: string;
  public celField: string;
  public preCelField: string;
  public prePhoneField: string;

  constructor(
    private _countryService: CountryService,
    private _userService: UserService,
    private _router: Router,
    private _auth: AuthService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.idioma = localStorage.getItem('idioma');
    this._prepareUser();
    this._form();
    this.paisSelected = null;
    this.edicao = AuthUtil.getEdicao();
    this.emailLogged = AuthUtil.getLogged();
    this._init();
  }
  selectCountry(event) {
    if (this.paisSelected) {
      this.isBr = this.paisSelected.sigla === 'BR';
    }
  }
  private async _getUser() {
    this.submitted = false;
    try {
      const retorno = await this._userService.getUser(this.emailLogged);
      this.user = retorno.entidade;
      this._formatUser();
    } catch (error) {
      Functions.openDialog(this.dialog, error);
    }
  }
  private _formatUser() {
    this.cpfField = Functions.zeroLeft(this.user.cpf, 11);
    this.cnpjField = Functions.zeroLeft(this.user.cnpj, 14);
    this.preCelField = Functions.zeroLeft(this.user.preCel, 2);
    this.prePhoneField = Functions.zeroLeft(this.user.preFone, 2);
    this.phoneField = Functions.zeroLeft(this.user.telefone, 11);
    this.celField = Functions.zeroLeft(this.user.celular, 12);
    this.paisSelected = this.user.endereco.pais;
    this.selectCountry(this.paisSelected);
  }
  async register() {
    this.submitted = true;
    try {
      if (this.user.senha && this.user.repetirSenha) {
        this.user.senha = Functions.simpleCode(this.user.senha);
        this.user.repetirSenha = Functions.simpleCode(this.user.repetirSenha);
      }
      this.user.telefone = Functions.number(this.phoneField);
      this.user.celular = Functions.number(this.celField);
      this.user.cpf = Functions.number(this.cpfField);
      this.user.cnpj = Functions.number(this.cnpjField);
      this.user.preFone = Functions.number(this.prePhoneField);
      this.user.preCel = Functions.number(this.preCelField);
      this.user.brasileiro = this.isBr;
      this.user.endereco.pais = this.paisSelected;
      this.user.idioma = this.idioma;
      let retorno;
      if(this.user.id){
        retorno = await this._userService.updateEspecialistaUser(this.user);
        Functions.openDialog(this.dialog, retorno, () => this._getUser());
      }else{
        retorno = await this._userService.saveEspecialistaUser(this.user);
        Functions.openDialog(this.dialog, retorno, () => this.goToLogin());
      }
    } catch (error) {
      Functions.openDialog(this.dialog, error);
    }
  }
  goToLogin() {
    this._router.navigateByUrl('painel/login');
  }
  private async _init() {
    if (this.edicao !== "null" && this.emailLogged !== "null") {
      this.disabled = true;
      this._getUser();
    } else {
      await this.obtainToken();
    }
    await this._getCountys();
  }
  private async obtainToken() {
    await this._auth.obtainAccessToken(null);
  }
  private async _getCountys() {
    try {
      const retorno = await this._countryService.getCounty(
        this.idioma,
        0,
        true
      );
      this.countrys = retorno.lista;
      this._initiCountry();
    } catch (error) {
      Functions.openDialog(this.dialog, error);
    }
  }
  private _initiCountry() {
    this.filteredCountrys.next(this.countrys.slice());
    this.countryFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCountrys();
      });
    this.setInitialValue();
  }
  protected filterCountrys() {
    if (!this.countrys) {
      return;
    }
    let search = this.countryFilterCtrl.value;
    if (!search) {
      this.filteredCountrys.next(this.countrys.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredCountrys.next(
      this.countrys.filter(
        (country) => country.nome.toLowerCase().indexOf(search) > -1
      )
    );
  }
  protected setInitialValue() {
    this.filteredCountrys
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        if (this.singleSelect) {
          this.singleSelect.compareWith = (a: Pais, b: Pais) =>
            a && b && a.id === b.id;
        }
      });
  }
  private _form() {
    this.formReg = new FormGroup({
      countrySelectedForm: new FormControl(this.paisSelected, [
        Validators.required,
      ]),
      nome: new FormControl(this.user.nome, [Validators.required]),
      email: new FormControl(this.user.email, [Validators.required]),
      senha: new FormControl(this.user.senha, [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(6),
      ]),
      repetirSenha: new FormControl(this.user.repetirSenha, [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(6),
      ]),
      endereco: new FormControl(this.user.endereco.endereco, [
        Validators.required,
      ]),
      numero: new FormControl(this.user.endereco.numero, [Validators.required]),
      bairro: new FormControl(this.user.endereco.bairro, [Validators.required]),
      cidade: new FormControl(this.user.endereco.cidade, [Validators.required]),
      estado: new FormControl(this.user.endereco.estado, [Validators.required]),
      ativo: new FormControl(this.user.ativo, []),
      cpf: new FormControl(this.user.cpf, []),
      cnpj: new FormControl(this.user.cnpj, []),
      telefone: new FormControl(this.user.telefone, []),
      celular: new FormControl(this.user.celular, []),
      regInternacional: new FormControl(this.user.regInternacional, [Validators.required]),
      preFone: new FormControl(this.user.preFone, []),
      preCel: new FormControl(this.user.preCel, []),
      complemento: new FormControl(this.user.endereco.complemento, []),
      profissao: new FormControl(this.user.profissao, []),
      cpfSelecionado: new FormControl(this.user, []),
      cnpjSelecionado: new FormControl(this.user, []),
    });
  }
  private _prepareUser() {
    this.user = new Usuario();
    this.user.endereco = new Endereco();
  }
  //Form
  get countrySelectedForm() {
    return this.formReg.get('countrySelectedForm');
  }
  get nome() {
    return this.formReg.get('nome');
  }
  get email() {
    return this.formReg.get('email');
  }
  get senha() {
    return this.formReg.get('senha');
  }
  get repetirSenha() {
    return this.formReg.get('repetirSenha');
  }
  get endereco() {
    return this.formReg.get('endereco');
  }
  get numero() {
    return this.formReg.get('numero');
  }
  get bairro() {
    return this.formReg.get('bairro');
  }
  get cidade() {
    return this.formReg.get('cidade');
  }
  get estado() {
    return this.formReg.get('estado');
  }
  get regInternacional() {
    return this.formReg.get('regInternacional');
  }
  get cnpj() {
    return this.formReg.get('cnpj');
  }
  get cpf() {
    return this.formReg.get('cpf');
  }
  get complemento() {
    return this.formReg.get('complemento');
  }
  get preFone() {
    return this.formReg.get('preFone');
  }
  get telefone() {
    return this.formReg.get('telefone');
  }
  get preCel() {
    return this.formReg.get('preCel');
  }
  get celular() {
    return this.formReg.get('celular');
  }
  get profissao() {
    return this.formReg.get('profissao');
  }
  get cpfSelecionado() {
    return this.formReg.get('cpfSelecionado');
  }
  get cnpjSelecionado() {
    return this.formReg.get('cnpjSelecionado');
  }
}
