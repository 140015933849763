<div class="container">
    <h1 class="line-background"><span>PERFIS</span></h1>
    <div class="row">
        <div class="col-sm">
            <div class="autocomplete">
                <div class="label autocomplete">Perfil</div>
            </div>
            <mat-form-field>
                <mat-select [(value)]="perfilSelecionado" id="perfil" (selectionChange)="selectProfile($event)">
                    <mat-option>
                        <ngx-mat-select-search [placeholderLabel]="''" [noEntriesFoundLabel]="''"
                            [formControl]="itemFilterCtrl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let perfil of filteredItens | async" [value]="perfil">
                        {{ perfil.valor }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf=perfilSelecionado>
                <fieldset class="form-group">
                    <label *ngIf=!perfilSelecionado.id for="nome" class="bmd-label-static">e-mail</label>
                    <label *ngIf=perfilSelecionado.id for="nome" class="bmd-label-static">Nome</label>
                    <input type="email" id="email" class="form-control" [(ngModel)]="nome" />
                </fieldset>
                <div class="row">
                    <div class="col-sm left botton"></div>
                    <div class="col-sm right">
                        <button class="btn btn-secondary" (click)="clear()">
                            LIMPAR
                        </button>
                        &nbsp;
                        <button type="submit" class="btn btn-primary" (click)="getUsersProfile()">
                            BUSCAR
                        </button>
                    </div>
                </div>
                <h2>{{ perfilSelecionado.valor }}: {{totalItens}}</h2>
                <div *ngIf="totalItens > 0">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">Opções</th>
                                <th scope="col" *ngIf=isNome>Nome</th>
                                <th scope="col">e-mail</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of lista">
                                <td class="center col-sm-2" (click)="nav(item)">
                                    <span *ngIf=isNome class="material-icons" title="Editar">
                                        mode
                                    </span>
                                    <span *ngIf=!isNome class="material-icons" title="Visualizar">
                                        visibility
                                    </span>
                                </td>
                                <td class="col-sm-6" *ngIf=isNome>{{item.nome}}</td>
                                <td class="col-sm-4" *ngIf=isNome>{{item.email}}</td>
                                <td class="col-sm-10" *ngIf=!isNome>{{item.email}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <nav aria-label="Page navigation example">
                        <ul class="pagination justify-content-end">
                            <li class="page-item" title="Primeira">
                                <a class="page-link" (click)="navPage(1)">
                                    <span aria-hidden="true">&laquo;</span>
                                    <span class="sr-only">Primeira</span>
                                </a>
                            </li>
                            <li class="page-item" title="Anterior">
                                <a class="page-link" (click)="navPageInter('a')">
                                    <span aria-hidden="true">&lt;</span>
                                    <span class="sr-only">Anterior</span>
                                </a>
                            </li>
                            <li class="page-item">
                                <a class="page-link">{{pageNumber +1}}</a>
                            </li>
                            <li class="page-item" title="Próxima">
                                <a class="page-link" (click)="navPageInter('p')">
                                    <span aria-hidden="true">&gt;</span>
                                    <span class="sr-only">Próxima</span>
                                </a>
                            </li>
                            <li class="page-item" title="Última">
                                <a class="page-link" (click)="navPage(paginas)" aria-label="Next">
                                    <span aria-hidden="true">&raquo;</span>
                                    <span class="sr-only">Última</span>
                                </a>
                            </li>
                            <div class="texto-paginacao">&nbsp;Total de Páginas: {{paginas}}</div>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</div>