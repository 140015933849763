import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-rodape',
  templateUrl: './rodape.component.html',
  styleUrls: ['./rodape.component.scss']
})
export class RodapeComponent implements OnInit {

  public data = new Date();

  constructor(private translateService: TranslateService) { }

  ngOnInit(): void {
  }

  public useLanguage(lang: string): void {
    localStorage.setItem('idioma', lang);
    this.translateService.setDefaultLang(lang);
    document.location.reload(true);
  }
}
