import { Pipe, PipeTransform } from '@angular/core';
import { Functions } from 'src/app/utils/functions';

@Pipe({
  name: 'zeros'
})
export class ZerosPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    return Functions.zeroLeft(value, args[0]);
  }

}
