import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Retorno } from 'src/app/model/retorno';
import { Usuario } from 'src/app/model/usuario';
import { HttpParams } from '@angular/common/http';
import { Destino } from 'src/app/model/destino';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private userPath = '/usuario';
  private userAdmPath = '/usuario/administrador';
  private userProfileAuth = '/usuario/autorizado/adm';
  private userProfileAuthSpec = `${this.userPath}/autorizado/especialista`;
  private userPassword = '/usuario/recuperarSenha';
  private userEspecialista = `${this.userPath}/especialista`;
  private especialistaPorTrecho = `${this.userPath}/especialista/trecho`;
  private usuarioPerfil = `${this.userPath}/perfil`

  constructor(private _auth: AuthService) { }

  getUser(email: string, idioma?: string): Promise<Retorno> {
    const idiomaParam = idioma !== undefined ? idioma : '';
    let params = new HttpParams().set('idioma', `${idiomaParam}`);
    return this._auth.getResource(`${this.userPath}/${email}`, params);
  }
  update(user: Usuario): Promise<Retorno> {
    return this._auth.putResource(`${this.userAdmPath}`, user);
  }
  getProfileAuth(): Promise<Retorno> {
    return this._auth.getResource(`${this.userProfileAuth}`);
  }
  updatePassoword(user: Usuario): Promise<Retorno> {
    return this._auth.putResource(`${this.userPassword}`, user);
  }
  saveEspecialistaUser(user: Usuario): Promise<Retorno> {
    return this._auth.postResource(`${this.userEspecialista}`, user);
  }
  especialistaTrecho(trecho: Destino): Promise<Retorno> {
    return this._auth.postResource(`${this.especialistaPorTrecho}`, trecho);
  }
  getProfileAuthSpecialist(): Promise<Retorno> {
    return this._auth.getResource(`${this.userProfileAuthSpec}`);
  }
  updateEspecialistaUser(user: Usuario): Promise<Retorno> {
    return this._auth.putResource(`${this.userEspecialista}`, user);
  }
  getUserPorfile(idioma: string, page: number, page_size: number, perfil?: number, nome?: string): Promise<Retorno> {
    let params;
    if (perfil) {
      params = new HttpParams().set('page', `${page}`).set('page_size', `${page_size}`).set('perfil', `${perfil}`).set('nome', `${nome}`);
    } else {
      params = new HttpParams().set('page', `${page}`).set('page_size', `${page_size}`).set('nome', `${nome}`);
    }
    return this._auth.getResource(`${this.usuarioPerfil}/${idioma}`, params);
  }
  updateAdmUser(user: Usuario): Promise<Retorno> {
    return this._auth.putResource(`${this.userAdmPath}`, user);
  }
}
