import { Usuario } from './usuario';
import { Pais } from './pais';
import { Destino } from './destino';

export class Mensagem {
  email: string;
  remetente: string;
  mensagem: string;
  preFone: number;
  telRemetente: number;
  cidadeRemetente: string;
  estadoRemetente: string;
  idioma: string;
  destinatario: Usuario;
  pais: Pais;
  trecho: Destino;
}
