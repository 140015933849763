import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/service/auth/auth.service';
import { UserService } from 'src/app/service/user/user.service';
import { Functions } from 'src/app/utils/functions';
import { MatDialog } from '@angular/material/dialog';
import { AuthUtil } from 'src/app/utils/auth';

@Component({
  selector: 'app-master',
  templateUrl: './master.component.html',
  styleUrls: ['./master.component.scss'],
})
export class MasterComponent implements OnInit {
  constructor(
    private _auth: AuthService,
    private _userService: UserService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this._isAuthUser();
    AuthUtil.setEdicao(null);
  }
  onLogout() {
    this._auth.logout();
  }
  private async _isAuthUser() {
    try {
      await this._userService.getProfileAuth();     
    } catch (error) {
      Functions.openDialog(this.dialog, error);
    }
  }
}
