import { Component, OnInit } from '@angular/core';
import { TransactionService } from 'src/app/service/transaction/transaction.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Functions } from 'src/app/utils/functions';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnInit {
  private _paymentId: string;
  private _payerId: string;
  constructor(
    private _transactionService: TransactionService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    public dialog: MatDialog
  ) {
    this._activatedRoute.queryParams.subscribe((params) => {
      this._paymentId = params['paymentId'];
      this._payerId = params['PayerID'];
    });
  }
  ngOnInit(): void {
    this._executePayment();
  }
  private async _executePayment() {
    try {
      const retorno = await this._transactionService.getPaymentePayPal(
        this._paymentId,
        this._payerId
      );
      Functions.openDialog(this.dialog, retorno, () => this._goPath());
    } catch (error) {
      Functions.openDialog(this.dialog, error, () => this._goPath());
    }
  }
  private _goPath(): void {
    this._router.navigateByUrl('/painel/strech');
  }
}
