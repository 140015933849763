import { HttpHeaders } from '@angular/common/http';

export class AuthUtil {
  static setToken(token, expiration?) {
    localStorage.setItem('token', token);
  }
  static getHeader(): HttpHeaders {
    return new HttpHeaders({
      'Content-type': 'application/json; charset=utf-8',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    });
  }
  static setLogin(login) {
    localStorage.setItem('login', login);
  }
  static getToken(): string {
    return localStorage.getItem('token');
  }
  static getLogged() {
    return localStorage.getItem('login');
  }
  static setEdicao(value) {
    localStorage.setItem('edicao', value);
  }
  static getEdicao() {
    return localStorage.getItem('edicao');
  }
  static logout() {
    this.setToken(null);
    this.setLogin(null);
  }
}
