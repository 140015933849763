<div class="container">
  <h1 class="line-background">
    <span>{{ "adquirir_atendimento" | translate }}</span>
  </h1>
  <br />
  <span>{{ "texto_pagamento_1" | translate }}</span>
  <b><span>{{ "texto_pagamento_2" | translate }}</span></b>
  <br />
  <br />
  <span>{{ "texto_pagamento_3" | translate }}</span>
  <br />
  <br />
  <span>{{ "texto_pagamento_4" | translate }}</span>
  <br />
  <br />
  <span>
    <label class="radio-inline" disabled>
      <input type="radio" [(ngModel)]="isCartao" name="cpfSelecionado" id="cpf" [value]="true" [disabled]="destinySelected != null"/>
      {{ "cartao" | translate }} </label>&nbsp;
    <label *ngIf="isBr" class="radio-inline">
      <input type="radio" [(ngModel)]="isCartao" name="cnpjSelecionado" id="cnpj" [value]="false" [disabled]="destinySelected != null"/>
      {{ "boleto" | translate }}
    </label>
  </span>
  <br />
  <br />
  <div class="row">
    <div class="col-sm">
      <div class="autocomplete">
        <span class="material-icons"> flight_takeoff </span>
        <span class="label autocomplete">{{ "origem" | translate }}</span>
      </div>
      <mat-form-field>
        <mat-select [formControl]="countryOriCtrl" [(value)]="originSelected" id="origem"
          (selectionChange)="selectOrigin($event)">
          <mat-option>
            <ngx-mat-select-search [placeholderLabel]="''" [noEntriesFoundLabel]="''"
              [formControl]="countryOriFilterCtrl"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let origin of filteredOriCountrys | async" [value]="origin">
            {{ origin.nome }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-sm">
      <div class="autocomplete">
        <span class="material-icons"> flight_land </span>
        <span class="label autocomplete">{{ "destino" | translate }}</span>
      </div>
      <mat-form-field>
        <mat-select [formControl]="countryDesCtrl" [(value)]="destinySelected" id="destino" [disabled]="!originSelected"
          (selectionChange)="selectDestiny($event)">
          <mat-option>
            <ngx-mat-select-search [placeholderLabel]="''" [noEntriesFoundLabel]="''"
              [formControl]="countryDesFilterCtrl"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let destino of filteredDesCountrys | async" [value]="destino">
            {{ destino.nome }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <span *ngIf="destinySelected && urlTransacao">
    <a class="btn btn-primary" href="{{ urlTransacao }}" role="button">{{ "realizar_pagamento" | translate }}</a>
  </span>
</div>