<div class="container">
    <h1 class="line-background"><span>INTERNAUTA</span></h1>
    <fieldset class="form-group">
        <label for="nome" class="bmd-label-static">e-mail</label>
        <input type="email" id="email" class="form-control" placeholder="e-mail" [(ngModel)]="user.email" disabled />
    </fieldset>
    <div *ngIf=lista>
        <h2>Mensagen(s): {{totalItens}}</h2>
        <div id="accordion">
            <div class="card" *ngFor="let item of lista; let i = index">
                <div class="card-header" [id]="'heading'+i">
                    <h5 class="mb-0">
                        <button class="btn btn-link" data-toggle="collapse" [attr.data-target]="'#collapse'+i"
                            aria-expanded="false" [attr.aria-controls]="'collapse'+i">
                            {{item.remetente}}
                        </button>
                    </h5>
                </div>

                <div [id]="'collapse'+i" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm">
                                <fieldset class="form-group">
                                    <label for="especialista" class="bmd-label-static">{{ "especialista" | translate
                                        }}</label>
                                    <input type="text" class="form-control" id="especialista" maxlength="45"
                                        [(ngModel)]="item.destinatario.nome" disabled />
                                </fieldset>
                            </div>
                        </div>                        
                        <fieldset class="form-group">
                            <label for="nome" class="bmd-label-static">{{ "nome" | translate }}</label>
                            <input type="text" class="form-control" id="nome" maxlength="45"
                                [(ngModel)]="item.remetente" disabled />
                        </fieldset>
                        <fieldset class="form-group">
                            <label for="email" class="bmd-label-static">{{ "email" | translate }}</label>
                            <input type="email" class="form-control" id="email" maxlength="45" [(ngModel)]="item.email"
                                disabled />
                        </fieldset>
                        <div class="row">
                            <div class="col-sm">
                                <fieldset class="form-group">
                                    <label for="pais" class="bmd-label-static">{{ "pais" | translate }}</label>
                                    <input type="text" class="form-control" id="pais" maxlength="45"
                                        [(ngModel)]="item.pais.nomePt" disabled />
                                </fieldset>
                            </div>
                            <div class="col-sm">
                                <fieldset class="form-group">
                                    <label for="estado" class="bmd-label-static">{{ "estado" | translate }}</label>
                                    <input type="text" class="form-control" id="estado" maxlength="50"
                                        [(ngModel)]="item.estadoRemetente" disabled />
                                </fieldset>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm">
                                <fieldset class="form-group">
                                    <label for="cidade" class="bmd-label-static">{{ "cidade" | translate }}</label>
                                    <input type="text" class="form-control" id="cidade" maxlength="50"
                                        [(ngModel)]="item.cidadeRemetente" disabled />
                                </fieldset>
                            </div>
                            <div class="col-sm">
                                <div class="row">
                                    <div class="col-sm-4">
                                        <fieldset class="form-group">
                                            <label for="preFone" class="bmd-label-static">{{
                                                "codigo" | translate
                                                }}</label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text" id="basic-addon1">+</span>
                                                </div>
                                                <input type="text" class="form-control" id="preFone"
                                                    [(ngModel)]="item.preFone" disabled />
                                            </div>
                                        </fieldset>
                                    </div>
                                    <div class="col-sm-8">
                                        <fieldset class="form-group">
                                            <label for="tel" class="bmd-label-static">{{
                                                "tel" | translate
                                                }}</label>
                                            <input type="text" class="form-control" id="tel" value="{{item.telRemetente | zeros:12}}"
                                                [textMask]="celMask" disabled />
                                        </fieldset>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <fieldset class="form-group">
                            <label for="texto" class="bmd-label-static">{{
                                "mensagem" | translate
                                }}</label>
                            <textarea class="form-control" id="texto" [(ngModel)]="item.mensagem" maxlength="1000"
                                disabled rows="3"></textarea>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
        <br />
        <nav aria-label="Page navigation example">
            <ul class="pagination justify-content-end">
                <li class="page-item" title="Primeira">
                    <a class="page-link" (click)="navPage(1)">
                        <span aria-hidden="true">&laquo;</span>
                        <span class="sr-only">Primeira</span>
                    </a>
                </li>
                <li class="page-item" title="Anterior">
                    <a class="page-link" (click)="navPageInter('a')">
                        <span aria-hidden="true">&lt;</span>
                        <span class="sr-only">Anterior</span>
                    </a>
                </li>
                <li class="page-item">
                    <a class="page-link">{{pageNumber +1}}</a>
                </li>
                <li class="page-item" title="Próxima">
                    <a class="page-link" (click)="navPageInter('p')">
                        <span aria-hidden="true">&gt;</span>
                        <span class="sr-only">Próxima</span>
                    </a>
                </li>
                <li class="page-item" title="Última">
                    <a class="page-link" (click)="navPage(paginas)" aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                        <span class="sr-only">Última</span>
                    </a>
                </li>
                <div class="texto-paginacao">&nbsp;Total de Páginas: {{paginas}}</div>
            </ul>
        </nav>
    </div>
    <div class="row">
        <div class="col-sm left botton"></div>
        <div class="col-sm right">
            <button class="btn btn-secondary" (click)="back()">
                VOLTAR
            </button>
        </div>
    </div>
</div>