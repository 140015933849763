import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Retorno } from 'src/app/model/retorno';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  private paymentePayPalPath = '/transacao/pagamento/paypal';
  constructor(private _auth: AuthService) {

   }
   getPaymentePayPal(paymentId: string, payerId: string, hideLoad?: boolean): Promise<Retorno> {
    return this._auth.getResource(`${this.paymentePayPalPath}/${paymentId}/${payerId}`, null, hideLoad);
  }
}
