<div class="container">
    <h1 class="line-background" *ngIf="disabled"><span>ESPECIALISTA</span></h1>
    <fieldset class="form-group">
        <label for="nome" class="bmd-label-static">{{'nome' | translate}}</label>
        <input type="text" class="form-control" id="nome" maxlength="45" [(ngModel)]="user.nome" [readonly]="disabled">
    </fieldset>
    <fieldset class="form-group">
        <label for="email" class="bmd-label-static">{{'email' | translate}}</label>
        <input type="email" class="form-control" id="email" maxlength="45" [(ngModel)]="user.email"
            [readonly]="disabled">
    </fieldset>
    <div class="row">
        <div class="col-sm">
            <div>
                <fieldset class="form-group">
                    <label for="paisLabel" class="bmd-label-static">{{'pais' | translate}}</label>
                    <input type="text" class="form-control" id="paisLabel" [readonly]="disabled"
                        [(ngModel)]="paisSelected.nome">
                </fieldset>
            </div>
        </div>
        <div class="col-sm">
            <fieldset class="form-group">
                <label for="estado" class="bmd-label-static">{{'estado' | translate}}</label>
                <input type="text" class="form-control" id="estado" maxlength="50" [(ngModel)]="user.endereco.estado"
                    [readonly]="disabled">
            </fieldset>
        </div>
    </div>
    <span *ngIf="isBr">
        <fieldset *ngIf="isCpf" class="form-group">
            <label for="cpfText" class="bmd-label-static">CPF</label>
            <input type="text" class="form-control" id="cpfText" [(ngModel)]="cpfField" [textMask]="cpfMask"
                [readonly]="disabled">
        </fieldset>
        <fieldset *ngIf="!isCpf" class="form-group">
            <label for="cnpjText" class="bmd-label-static">CNPJ</label>
            <input type="text" class="form-control" id="cnpjText" [(ngModel)]="cnpjField" [textMask]="cnpjMask"
                [readonly]="disabled">
        </fieldset>
    </span>
    <span *ngIf="!isBr">
        <fieldset class="form-group">
            <label for="regint" class="bmd-label-static">{{'regint' | translate}}</label>
            <input type="text" class="form-control" id="regint" maxlength="50" [(ngModel)]="user.regInternacional"
                [readonly]="disabled">
        </fieldset>
    </span>
    <fieldset class="form-group">
        <label for="endereco" class="bmd-label-static">{{'endereco' | translate}}</label>
        <input type="text" class="form-control" id="endereco" maxlength="500" [(ngModel)]="user.endereco.endereco"
            [readonly]="disabled">
    </fieldset>
    <div class="row">
        <div class="col-sm">
            <fieldset class="form-group">
                <label for="numero" class="bmd-label-static">{{'numero' | translate}}</label>
                <input type="text" class="form-control" id="numero" maxlength="10" [(ngModel)]="user.endereco.numero"
                    [readonly]="disabled">
            </fieldset>
        </div>
        <div class="col-sm">
            <fieldset class="form-group">
                <label for="complemento" class="bmd-label-static">{{'complemento' | translate}}</label>
                <input type="text" class="form-control" id="complemento" maxlength="200"
                    [(ngModel)]="user.endereco.complemento" [readonly]="disabled">
            </fieldset>
        </div>
    </div>
    <div class="row">
        <div class="col-sm">
            <fieldset class="form-group">
                <label for="bairro" class="bmd-label-static">{{'bairro' | translate}}</label>
                <input type="text" class="form-control" id="bairro" maxlength="50" [(ngModel)]="user.endereco.bairro"
                    [readonly]="disabled">
            </fieldset>
        </div>
        <div class="col-sm">
            <fieldset class="form-group">
                <label for="cidade" class="bmd-label-static">{{'cidade' | translate}}</label>
                <input type="text" class="form-control" id="cidade" maxlength="50" [(ngModel)]="user.endereco.cidade"
                    [readonly]="disabled">
            </fieldset>
        </div>
    </div>
    <div class="row">
        <div class="col-sm">
            <div class="row">
                <div class="col-sm-4">
                    <fieldset class="form-group">
                        <label for="preTel" class="bmd-label-static">{{'codigo' | translate}}</label>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon1">+</span>
                            </div>
                            <input type="text" class="form-control" id="preTel" [(ngModel)]="prePhoneField"
                                [textMask]="codinMask" [readonly]="disabled">
                        </div>
                    </fieldset>
                </div>
                <div class="col-sm-8">
                    <fieldset class="form-group">
                        <label for="tel" class="bmd-label-static">{{'tel' | translate}}</label>
                        <input type="text" class="form-control" id="tel" [(ngModel)]="phoneField" [textMask]="phoneMask"
                            [readonly]="disabled">
                    </fieldset>
                </div>
            </div>
        </div>
        <div class="col-sm">
            <div class="row">
                <div class="col-sm-4">
                    <fieldset class="form-group">
                        <label for="preCel" class="bmd-label-static">{{'codigo' | translate}}</label>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon1">+</span>
                            </div>
                            <input type="text" class="form-control" id="preCel" [(ngModel)]="preCelField"
                                [textMask]="codinMask" [readonly]="disabled">
                        </div>
                    </fieldset>
                </div>
                <div class="col-sm-8">
                    <fieldset class="form-group">
                        <label for="cel" class="bmd-label-static">{{'cel' | translate}}</label>
                        <input type="text" class="form-control" id="cel" [(ngModel)]="celField" [textMask]="celMask"
                            [readonly]="disabled">
                    </fieldset>
                </div>
            </div>

        </div>
    </div>
    <fieldset class="form-group">
        <label for="profissao" class="bmd-label-static">{{'profissao' | translate}}</label>
        <input type="text" class="form-control" id="profissao" [(ngModel)]="user.profissao" maxlength="45"
            [readonly]="disabled">
    </fieldset>
    <div *ngIf=lista>
        <h2>Atendimento(s): {{totalItens}}</h2>

        <div id="accordion">
            <div class="card" *ngFor="let item of lista; let i = index">
                <div class="card-header" [id]="'heading'+i">
                    <h5 class="mb-0">
                        <button class="btn btn-link" data-toggle="collapse" [attr.data-target]="'#collapse'+i"
                            aria-expanded="false" [attr.aria-controls]="'collapse'+i">
                            <b>Origen:</b> {{item.trecho.origem.nomePt}}&nbsp;<b>Destino:</b>
                            {{item.trecho.destino.nomePt}}
                        </button>
                    </h5>
                </div>
                <div [id]="'collapse'+i" class="collapse" [ngClass]="selecionado === item.id? 'show' : ''" 
                aria-labelledby="headingOne" data-parent="#accordion">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm">
                                <div class="form-group">
                                    <label class="bmd-label-static checkbox" for="status"> Ativo&nbsp;&nbsp;
                                        <input type="checkbox" value="" id="status" [(ngModel)]="item.ativo">
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm">
                                <fieldset class="form-group">
                                    <label for="data" class="bmd-label-static">Data Final da Vigência</label>
                                    <div class="form-group">
                                        <div class="input-group">
                                            <input class="form-control mini" name="dp"
                                                [(ngModel)]="item.dataModelFimVigencia" ngbDatepicker #d="ngbDatepicker"
                                                readonly [disabled]="!item.ativo || item.ativo=== 'false'">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar button-calendar"
                                                    (click)="d.toggle()" type="button">
                                                    <span class="material-icons"
                                                        [ngClass]="!item.ativo || item.ativo=== 'false'? 'disabled' : ''"
                                                        title="Calendário">
                                                        event
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm left botton"></div>
                            <div class="col-sm right">
                                <button type="submit" class="btn btn-primary" (click)="update(item)">
                                    ALTERAR
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br />
        <nav aria-label="Page navigation example">
            <ul class="pagination justify-content-end">
                <li class="page-item" title="Primeira">
                    <a class="page-link" (click)="navPage(1)">
                        <span aria-hidden="true">&laquo;</span>
                        <span class="sr-only">Primeira</span>
                    </a>
                </li>
                <li class="page-item" title="Anterior">
                    <a class="page-link" (click)="navPageInter('a')">
                        <span aria-hidden="true">&lt;</span>
                        <span class="sr-only">Anterior</span>
                    </a>
                </li>
                <li class="page-item">
                    <a class="page-link">{{pageNumber +1}}</a>
                </li>
                <li class="page-item" title="Próxima">
                    <a class="page-link" (click)="navPageInter('p')">
                        <span aria-hidden="true">&gt;</span>
                        <span class="sr-only">Próxima</span>
                    </a>
                </li>
                <li class="page-item" title="Última">
                    <a class="page-link" (click)="navPage(paginas)" aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                        <span class="sr-only">Última</span>
                    </a>
                </li>
                <div class="texto-paginacao">&nbsp;Total de Páginas: {{paginas}}</div>
            </ul>
        </nav>
    </div>
    <div class="row">
        <div class="col-sm left botton"></div>
        <div class="col-sm right">
            <button class="btn btn-secondary" (click)="back()">
                VOLTAR
            </button>
        </div>
    </div>
</div>