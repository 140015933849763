import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Retorno } from 'src/app/model/retorno';
import { Functions } from 'src/app/utils/functions';
import { UserService } from 'src/app/service/user/user.service';
import { Usuario } from 'src/app/model/usuario';
import { AuthUtil } from 'src/app/utils/auth';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent implements OnInit {
  public user: Usuario;
  public formUser;
  public submitted;

  constructor(private _userService: UserService, public dialog: MatDialog) {}

  ngOnInit(): void {
    this.user = new Usuario();
    this.user.email = AuthUtil.getLogged();
    this.submitted = false;
    this._getUser();
    this._form();
  }
  async update() {
    this.submitted = true;
    try {
      if(this.user.senha && this.user.repetirSenha){
        this.user.senha = Functions.simpleCode(this.user.senha);
        this.user.repetirSenha = Functions.simpleCode(this.user.repetirSenha);
      }
      const retorno = await this._userService.update(this.user);
      AuthUtil.setLogin(this.user.email);
      Functions.openDialog(this.dialog, retorno, ()=>this.ngOnInit());
    } catch (error) {
      Functions.openDialog(this.dialog, error);
    }
  }
  private async _getUser() {
    try {
      const retorno = await this._userService.getUser(this.user.email);
      this.user = retorno.entidade;
    } catch (error) {
      Functions.openDialog(this.dialog, error);
    }
  }
  private _form() {
    this.formUser = new FormGroup({
      nome: new FormControl(this.user.nome, [Validators.required]),
      email: new FormControl(this.user.email, [Validators.required]),
      novaSenha: new FormControl(this.user.senha, [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(6),
      ]),
      repetirSenha: new FormControl(this.user.repetirSenha, [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(6),
      ]),
    });
  }
  get nome() {
    return this.formUser.get('nome');
  }
  get email() {
    return this.formUser.get('email');
  }
  get novaSenha() {
    return this.formUser.get('novaSenha');
  }
  get repetirSenha() {
    return this.formUser.get('repetirSenha');
  }
}
