<h1 class="line-background">
  <span>{{ "contato" | translate }}</span>
</h1>
<br />
<div class="row">
  <div class="col-sm center">
    <span class="material-icons"> email </span>
    <br />atendimento@legaltrip.com.br
  </div>
</div>
