<app-topo></app-topo>
<div class="container">
  <h1 class="line-background">
    <span>{{ "login" | translate }}</span>
  </h1>
  <div class="row">
    <div class="col-xl-3 .col-lg-3 .col-md-3 .col-sm-0 .col-0"></div>
    <div class="col-xl-6 .col-lg-6 .col-md-6 .col-sm-12 .col-12 frame">
      <form [formGroup]="formLogin" #formDir="ngForm">
        <fieldset class="form-group">
          <label for="email" class="bmd-label-static"
            >{{ "email" | translate }}*</label
          >
          <input
            type="email"
            class="form-control"
            id="email"
            formControlName="usuario"
            [(ngModel)]="login.usuario"
            [ngClass]="usuario.invalid && submitted ? 'danger' : ''"
          />
        </fieldset>
        <fieldset class="form-group">
          <label for="senha" class="bmd-label-static"
            >{{ "senha" | translate }}*</label
          >
          <input
            type="password"
            class="form-control"
            id="senha"
            formControlName="senha"
            [(ngModel)]="login.senha"
            [ngClass]="senha.invalid && submitted ? 'danger' : ''"
          />
        </fieldset>
        <div class="row">
          <div
            class="col-xl-6 .col-lg-6 .col-md-6 .col-sm-12 .col-12 left botton"
          >
            <a
              routerLink="/painel/password_recovery"
              routerLinkActive="active"
              >{{ "esqueci_senha" | translate }}</a
            >
          </div>
          <div class="col-xl-6 .col-lg-6 .col-md-6 .col-sm-12 .col-12 right">
            <button type="submit" class="btn btn-primary" (click)="onLogin()">
              {{ "entrar" | translate }}
            </button>
          </div>
        </div>
        <br />
        <div class="row center">
          <a routerLink="/painel/register" routerLinkActive="active">{{
            "registro" | translate
          }}</a>
        </div>
      </form>
    </div>
    <div class="col-xl-3 .col-lg-3 .col-md-3 .col-sm-0 .col-0"></div>
  </div>
</div>
<app-rodape></app-rodape>
