import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
import { Functions } from './functions';

@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
  parse(value: string): NgbDateStruct {
    if (value) {
      const dateParts = value.trim().split('-');
      if (dateParts.length === 1 && Functions.isNumber(dateParts[0])) {
        return { day: Functions.number(dateParts[0]), month: null, year: null };
      } else if (dateParts.length === 2 && Functions.isNumber(dateParts[0]) && Functions.isNumber(dateParts[1])) {
        return { day: Functions.number(dateParts[0]), month: Functions.number(dateParts[1]), year: null };
      } else if (dateParts.length === 3 && Functions.isNumber(dateParts[0]) && Functions.isNumber(dateParts[1]) && Functions.isNumber(dateParts[2])) {
        return { day: Functions.number(dateParts[0]), month: Functions.number(dateParts[1]), year: Functions.number(dateParts[2]) };
      }
    }
    return null;
  }

  format(date: NgbDateStruct): string {
    return date ?
      `${Functions.isNumber(date.day) ? Functions.number(date.day) : Functions.zeroLeft(date.day, 2)}/${Functions.isNumber(date.month) ? Functions.number(date.month) : Functions.zeroLeft(date.month, 2)}/${date.year}` :
      '';
  }
}