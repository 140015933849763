<div class="container">
  <h1 class="line-background"><span>USUÁRIO</span></h1>
  <form [formGroup]="formUser" #formDir="ngForm">
    <div class="row">
      <div class="col-xl-3 .col-lg-3 .col-md-3 .col-sm-0 .col-0"></div>
      <div class="col-xl-6 .col-lg-6 .col-md-6 .col-sm-12 .col-12 frame">
        <fieldset class="form-group">
          <label for="nome" class="bmd-label-static">Nome*</label>
          <input
            type="text"
            id="nome"
            class="form-control"
            [(ngModel)]="user.nome"
            formControlName="nome"
            [ngClass]="nome.invalid && submitted ? 'danger' : ''"
          />
        </fieldset>
        <fieldset class="form-group">
          <label for="nome" class="bmd-label-static">e-mail*</label>
          <input
            type="email"
            id="email"
            class="form-control"
            placeholder="e-mail"
            [(ngModel)]="user.email"
            formControlName="email"
            [ngClass]="email.invalid && submitted ? 'danger' : ''"
            disabled
          />
        </fieldset> 
        <fieldset class="form-group">
          <label for="senha" class="bmd-label-static">Nova Senha*</label>
          <input
            type="password"
            id="senha"
            class="form-control"
            placeholder="Senha"
            [(ngModel)]="user.senha"
            formControlName="novaSenha"
            [ngClass]="novaSenha.invalid && submitted ? 'danger' : ''"
          />
        </fieldset>  
        <fieldset class="form-group">
          <label for="reSenha" class="bmd-label-static">Repetir Senha*</label>
          <input
            type="password"
            id="reSenha"
            class="form-control"
            placeholder="Repetir Senha"
            [(ngModel)]="user.repetirSenha"
            formControlName="repetirSenha"
            [ngClass]="repetirSenha.invalid && submitted ? 'danger' : ''"
          />
        </fieldset>      
        <div class="row">
          <div class="col-sm left botton"></div>
          <div class="col-sm right">
            <button type="submit" class="btn btn-primary" (click)="update()">
              ALTERAR
            </button>
          </div>
        </div>
      </div>
      <div class="col-xl-3 .col-lg-3 .col-md-3 .col-sm-0 .col-0"></div>
    </div>
  </form>
</div>
