<h1 class="line-background">
  <span>{{ "trecho" | translate }}</span>
</h1>
<div class="row">
  <div class="col-sm">
    <div class="autocomplete">
      <span class="material-icons"> flight_takeoff </span>
      <span class="label autocomplete">{{ "origem" | translate }}</span>
    </div>
    <mat-form-field>
      <mat-select
        [formControl]="countryOriCtrl"
        [(value)]="originSelected"
        id="origem"
        (selectionChange)="selectOrigin($event)"
      >
        <mat-option>
          <ngx-mat-select-search
            [placeholderLabel]="''"
            [noEntriesFoundLabel]="''"
            [formControl]="countryOriFilterCtrl"
          ></ngx-mat-select-search>
        </mat-option>
        <mat-option
          *ngFor="let origin of filteredOriCountrys | async"
          [value]="origin"
        >
          {{ origin.nome }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-sm">
    <div class="autocomplete">
      <span class="material-icons"> flight_land </span>
      <span class="label autocomplete">{{ "destino" | translate }}</span>
    </div>
    <mat-form-field>
      <mat-select
        [formControl]="countryDesCtrl"
        [(value)]="destinySelected"
        id="destino"
        [disabled]="!originSelected"
        (selectionChange)="selectDestiny($event)"
      >
        <mat-option>
          <ngx-mat-select-search
            [placeholderLabel]="''"
            [noEntriesFoundLabel]="''"
            [formControl]="countryDesFilterCtrl"
          ></ngx-mat-select-search>
        </mat-option>
        <mat-option
          *ngFor="let destino of filteredDesCountrys | async"
          [value]="destino"
        >
          {{ destino.nome }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<br />
<span *ngIf="destinySelected">
  <br />
  <div class="center">
    <img [src]="destinySelected.bandeiraImg" id="imageBand" />
    <h2>{{ destinySelected.nome }}</h2>
    {{ destinySelected.informacao }}
  </div>
  <mat-tab-group mat-align-tabs="center">
    <mat-tab label="{{ 'turismo' | translate }}">
      <br/>
      <div [innerHTML]="document.descTurismo"></div>
    </mat-tab>
    <mat-tab label="{{ 'resi_temp' | translate }}">
      <br/>
      <div [innerHTML]="document.descResiTemp"></div>
    </mat-tab>
    <mat-tab label="{{ 'resi_fixa' | translate }}">
      <br/>
      <div [innerHTML]="document.descResiFixa"></div>
    </mat-tab>
    <mat-tab label="{{ 'embaixada' | translate }}">
      <br/>
      <div [innerHTML]="document.embaixada"></div>
    </mat-tab>
  </mat-tab-group>
</span>
<div class="justificar" *ngIf="!destinySelected">
  <span>{{ "selecione_trecho_1" | translate }}</span>
  <br />
  <br />
  <span>{{ "selecione_trecho_2" | translate }}</span>
  <span>{{ "selecione_trecho_3" | translate }}</span>
  <span>{{ "selecione_trecho_4" | translate }}</span>
  <br />
  <br />
  <ul><li><span>{{ "selecione_trecho_5" | translate }}</span></li></ul>
</div>
<div id="especialista" class="bottom">
  <div *ngIf="specialists">
    <app-specialist
      [specialists]="specialists"
      [trecho]="trecho"
    ></app-specialist>
  </div>
  <div class="justificar" *ngIf="!specialists && destinySelected">
    <br />
    <br />
    {{ "mesagem_sem_especialista" | translate
    }}<b
      ><u><a routerLink="/painel/register" routerLinkActive="active">{{
        "registro" | translate
      }}</a></u></b
    >. {{ "mesagem_sem_especialista_continua" | translate }}
    <br />
    <b>{{ "mesagem_sem_especialista_negrito" | translate }}</b>
  </div>
</div>
