import { Pipe, PipeTransform } from '@angular/core';
import { Functions } from 'src/app/utils/functions';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    return Functions.createMaskPhone(value);
  }

}
