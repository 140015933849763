import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PerfilEnum } from 'src/app/model/enuns/perfil';
import CelMask from 'src/app/model/mask/cel';
import CodinMask from 'src/app/model/mask/codin';
import { Mensagem } from 'src/app/model/mensagem';
import { Pais } from 'src/app/model/pais';
import { MessageService } from 'src/app/service/message/message.service';
import { Functions } from 'src/app/utils/functions';

@Component({
  selector: 'app-view-inter',
  templateUrl: './view-inter.component.html',
  styleUrls: ['./view-inter.component.scss']
})
export class ViewInterComponent implements OnInit {

  public user: Mensagem;
  public lista: Mensagem[];
  public pageNumber: number = 0;
  public pageSize: number = 5;
  public totalItens = 0;
  public paginas = 1;

  public celMask = new CelMask();
  public codinMask = new CodinMask();
  public idioma: string = 'pt';

  constructor(private _router: Router, private _mesgService: MessageService, public dialog: MatDialog) {
    this.user = this._router.getCurrentNavigation().extras as Mensagem;
  }

  ngOnInit(): void {
    localStorage.setItem('idioma', this.idioma);
    this._getMesageList();
  }

  back() {
    this._router.navigate(['/painel/profiles'], PerfilEnum.INTERNAUTA as any);
  }

  navPage(page: number) {
    this.pageNumber = page - 1;
    this._getMesageList();
  }

  navPageInter(sentido: string) {

    if (sentido == 'a' && this.pageNumber > 0) {
      this.pageNumber = this.pageNumber - 1;
      this._getMesageList();
    } else if (sentido == 'p' && this.pageNumber < this.paginas - 1) {
      this.pageNumber = this.pageNumber + 1;
      this._getMesageList();
    }
  }

  zerosLeft(valor){
    return Functions.zeroLeft(valor, 12);
  }

  private async _getMesageList() {
    try {
      const retorno = await this._mesgService.getFromEmail(this.user.email, this.pageNumber, this.pageSize);
      this.lista = retorno.lista;
      this.totalItens = retorno.totalElementos;
      this.paginas = retorno.totalPaginas;
    } catch (error) {
      Functions.openDialog(this.dialog, error);
    }
  }

}
