import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { Router } from '@angular/router';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { PerfilEnum } from 'src/app/model/enuns/perfil';
import { Item } from 'src/app/model/item';
import { UserService } from 'src/app/service/user/user.service';
import { Functions } from 'src/app/utils/functions';
import * as perfis from '../../../assets/json/perfil.json';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-profiles',
  templateUrl: './profiles.component.html',
  styleUrls: ['./profiles.component.scss']
})
export class ProfilesComponent implements OnInit {

  public perfisSelect: Item[];
  public perfilSelecionado: Item;
  public pageNumber: number = 0;
  public pageSize: number = 5;
  public lista = [];
  public totalItens = 0;
  public paginas = 1;
  public isNome = false;
  public nome = '';
  private _tela = null;
  public idioma: string = 'pt';

  protected _onDestroy = new Subject<void>();
  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
  public itemFilterCtrl: FormControl = new FormControl();
  public filteredItens: ReplaySubject<Item[]> = new ReplaySubject<Item[]>(1);

  displayedColumns: string[] = ['nome', 'email'];

  constructor(private _userService: UserService,
    private _router: Router,
    private translateService: TranslateService,
    public dialog: MatDialog) {
    if (this._router.getCurrentNavigation().extras === PerfilEnum.INTERNAUTA
      || this._router.getCurrentNavigation().extras === PerfilEnum.ESPECIALISTA
      || this._router.getCurrentNavigation().extras === PerfilEnum.ADMINISTRADOR) {

      this.perfilSelecionado = new Item();
      this._tela = this._router.getCurrentNavigation().extras as number;
    }
  }

  ngOnInit(): void {
    this.perfisSelect = (perfis as any).default;
    this._initItens();
    if (this._tela != null) {
      this._tela = this._tela === 0 ? null : this._tela;
      this.perfilSelecionado = this.perfisSelect.find(perfil => this._tela === perfil.id);
      this.getUsersProfile();
    }
    this.translateService.setDefaultLang(this.idioma);
    localStorage.setItem('idioma', this.idioma);
  }

  public selectProfile(event) {
    this.perfilSelecionado = event.value;
    this.pageNumber = 0;
    this.nome = '';
    this.getUsersProfile();
  }

  public navPage(page: number) {
    this.pageNumber = page - 1;
    this.getUsersProfile();
  }

  public navPageInter(sentido: string) {

    if (sentido == 'a' && this.pageNumber > 0) {
      this.pageNumber = this.pageNumber - 1;
      this.getUsersProfile();
    } else if (sentido == 'p' && this.pageNumber < this.paginas - 1) {
      this.pageNumber = this.pageNumber + 1;
      this.getUsersProfile();
    }
  }

  public nav(item) {
    if (PerfilEnum.ADMINISTRADOR == this.perfilSelecionado.id) {
      this._router.navigate(['/painel/edit_adm'], item);
    } else if (PerfilEnum.ESPECIALISTA == this.perfilSelecionado.id) {
      this._router.navigate(['/painel/edit_specialist'], item);
    } else {
      this._router.navigate(['/painel/view_inter'], item);
    }
  }

  clear() {
    this.nome = '';
    this.getUsersProfile();
  }

  async getUsersProfile() {
    try {
      if (this.perfilSelecionado.id === PerfilEnum.INTERNAUTA) {
        this.perfilSelecionado.id = null;
      }
      const retorno = await this._userService.getUserPorfile('pt', this.pageNumber, this.pageSize, this.perfilSelecionado.id, this.nome);
      this.lista = retorno.lista;
      this.totalItens = retorno.totalElementos;
      this.isNome = this.perfilSelecionado.id != null;
      this.paginas = retorno.totalPaginas;
    } catch (error) {
      Functions.openDialog(this.dialog, error);
    }
  }

  private _initItens() {
    this.filteredItens.next(this.perfisSelect.slice());
    this.itemFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterperfisSelect();
      });
    this.setInitialValue();
  }
  protected filterperfisSelect() {
    if (!this.perfisSelect) {
      return;
    }
    let search = this.itemFilterCtrl.value;
    if (!search) {
      this.filteredItens.next(this.perfisSelect.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredItens.next(
      this.perfisSelect.filter(
        (item) => item.valor.toLowerCase().indexOf(search) > -1
      )
    );
  }
  protected setInitialValue() {
    this.filteredItens
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        if (this.singleSelect) {
          this.singleSelect.compareWith = (a: Item, b: Item) =>
            a && b && a.id === b.id;
        }
      });
  }
}
