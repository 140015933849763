<div class="container">
  <h1 class="line-background"><span>PAÍS</span></h1>
  <form [formGroup]="formPais" #formDir="ngForm">
    <div class="autocomplete">
      <div class="label autocomplete">País*</div>
    </div>
    <div class="ng-autocomplete">
      <mat-form-field>
        <mat-select
          [formControl]="countryCtrl"
          [(value)]="pais"
          id="pais"
          formControlName="id"
          [ngClass]="id.invalid && submitted ? 'danger' : ''"
          (selectionChange)="selectCountry($event)"
        >
          <mat-option>
            <ngx-mat-select-search
              [placeholderLabel]="''"
              [noEntriesFoundLabel]="''"
              [formControl]="countryFilterCtrl"
            >
            </ngx-mat-select-search>
          </mat-option>
          <mat-option
            *ngFor="let pais of filteredCountrys | async"
            [value]="pais"
          >
            {{ pais.nome }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div class="center">
        <img [src]="pais.bandeiraImg" id="image" />
        <br />
        <button
          type="button"
          (click)="fileInput.click()"
          class="btn btn-primary"
          [disabled]="!(pais && pais.id)"
        >
          <span *ngIf="pais && !pais.bandeiraImg">Adicionar Bandeira</span>
          <span *ngIf="pais && pais.bandeiraImg">Alterar Bandeira</span>
          <input
            #fileInput
            type="file"
            (change)="onFileSelected($event)"
            style="display: none"
            accept=".png, .jpg, .jpeg"
            id="uploadUmage"
          />
        </button>
      </div>
      <mat-tab-group mat-align-tabs="center" id="tabPais">
        <mat-tab label="Português">
          <div class="row pais">
            <div class="col-sm">
              <fieldset class="form-group">
                <label for="nomePt" class="bmd-label-static">Nome*</label>
                <input
                  type="text"
                  class="form-control"
                  id="nomePt"
                  [(ngModel)]="pais.nomePt"
                  formControlName="nomePt"
                  [ngClass]="nomePt.invalid && submitted ? 'danger' : ''"
                />
              </fieldset>
            </div>
            <div class="col-sm">
              <fieldset class="form-group">
                <label for="sigla" class="bmd-label-static">Sigla</label>
                <input
                  type="text"
                  class="form-control"
                  id="sigla"
                  [(ngModel)]="pais.sigla"
                  formControlName="sigla"
                  [ngClass]="sigla.invalid && submitted ? 'danger' : ''"
                  disabled
                />
              </fieldset>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-sm 12">
              Informações
              <textarea
                class="form-control"
                id="informacaoPt"
                rows="5"
                [(ngModel)]="pais.informacaoPt"
                formControlName="informacaoPt"
              ></textarea>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Inglês">
          <div class="row pais">
            <div class="col-sm 12">
              <fieldset class="form-group">
                <label for="nomeEn" class="bmd-label-static">Nome</label>
                <input
                  type="text"
                  id="nomeEn"
                  class="form-control"
                  [(ngModel)]="pais.nomeEn"
                  formControlName="nomeEn"
                  [ngClass]="nomeEn.invalid && submitted ? 'danger' : ''"
                />
              </fieldset>
            </div>
          </div>
          <div class="row">
            <br />
            <div class="col-sm 12">
              Informações
              <textarea
                class="form-control"
                id="informacaoEn"
                rows="5"
                [(ngModel)]="pais.informacaoEn"
                formControlName="informacaoEn"
              ></textarea>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Espanhol">
          <div class="row pais">
            <div class="col-sm 12">
              <fieldset class="form-group">
                <label for="nomeEs" class="bmd-label-static">Nome</label>
                <input
                  type="text"
                  id="nomeEs"
                  class="form-control"
                  [(ngModel)]="pais.nomeEs"
                  formControlName="nomeEs"
                  [ngClass]="nomeEs.invalid && submitted ? 'danger' : ''"
                />
              </fieldset>
            </div>
          </div>
          <div class="row">
            <div class="col-sm 12">
              Informações
              <textarea
                class="form-control"
                id="informacaoEs"
                rows="5"
                [(ngModel)]="pais.informacaoEs"
                formControlName="informacaoEs"
              ></textarea>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
      <br />
      <div class="row">
        <div class="col-sm left botton"></div>
        <div class="col-sm right">
          <button type="submit" class="btn btn-primary" (click)="save()">
            SALVAR
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
