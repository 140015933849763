import { Pais } from './pais';

export class Endereco {
    endereco: string;
    complemento: string;
    numero: string;
    bairro: string;
    cidade: string;
    estado: string;
    pais: Pais;
}