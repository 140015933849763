import { Component, OnInit, ViewChild } from '@angular/core';
import { StretchService } from 'src/app/service/stretch/stretch.service';
import { CountryService } from 'src/app/service/country/country.service';
import { Pais } from 'src/app/model/pais';
import { Documento } from 'src/app/model/documento';
import { Functions } from 'src/app/utils/functions';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/service/auth/auth.service';
import { MatSelect } from '@angular/material/select';
import { Subject, ReplaySubject } from 'rxjs';
import { FormControl } from '@angular/forms';
import { take, takeUntil } from 'rxjs/operators';
import { UserService } from 'src/app/service/user/user.service';
import { Destino } from 'src/app/model/destino';
import { Usuario } from 'src/app/model/usuario';

@Component({
  selector: 'app-information-home',
  templateUrl: './information-home.component.html',
  styleUrls: ['./information-home.component.scss'],
})
export class InformationHomeComponent implements OnInit {
  protected _onDestroy = new Subject<void>();
  protected _onDestroyDes = new Subject<void>();
  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
  @ViewChild('singleSelect', { static: true }) singleSelectDes: MatSelect;

  public originSelected: Pais;
  public destinySelected: Pais;
  public document: Documento;
  public countrysOri: Pais[];
  public countrysDest: Pais[];
  public specialists: Usuario[];
  public idioma: string = 'pt';
  public trecho: Destino;

  //Origem
  public countryOriCtrl: FormControl = new FormControl();
  public countryOriFilterCtrl: FormControl = new FormControl();
  public filteredOriCountrys: ReplaySubject<Pais[]> = new ReplaySubject<Pais[]>(
    1
  );
  //Destino
  public countryDesCtrl: FormControl = new FormControl();
  public countryDesFilterCtrl: FormControl = new FormControl();
  public filteredDesCountrys: ReplaySubject<Pais[]> = new ReplaySubject<Pais[]>(
    1
  );
  constructor(
    private _countryService: CountryService,
    private _stretch: StretchService,
    private _user: UserService,
    private _auth: AuthService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.idioma = localStorage.getItem("idioma");
    this.originSelected = null;
    this.destinySelected = null;
    this.countrysOri = [];
    this.countrysDest = null;
    this.document = new Documento();
    this.specialists = null;
    this._init();
  }
  selectOrigin(event) {
    this.document = new Documento();
    this.specialists = null;
    if (this.originSelected) {
      this.destinySelected = null;
      this._getCountys(this.originSelected.id);
    } else {
      this.destinySelected = null;
    }
  }
  selectDestiny(event) {
    if (this.originSelected && this.destinySelected) {
      this._getStretch();
    }
  }
  
  private _brasil(){
    
    this.originSelected = this.countrysOri.filter(
      (pais) => pais.sigla === 'BR'
    )[0];
    this.selectOrigin('a');
  }
  private async _init() {
    await this.obtainToken();
    await this._getCountys();
  }
  private async obtainToken() {
    await this._auth.obtainAccessToken(null);
  }
  private async _getCountys(id?: number) {
    try {
      const retorno = await this._countryService.getCounty(
        this.idioma,
        id,
        true
      );
      if (id) {
        this.countrysDest = retorno.lista;
        this._initiCountryDes();
      } else {
        //FIltro para manter Brasil como Origen
        this.countrysOri = retorno.lista.filter(
          (pais) => pais.sigla === 'BR'
        );
        this._initiCountryOri();
      }
    } catch (error) {
      Functions.openDialog(this.dialog, error);
    }
  }
  private async _getStretch() {
    try {
      const retorno = await this._stretch.getStretch(
        this.originSelected.id,
        this.destinySelected.id,
        this.idioma,
        true
      );
      this.document = retorno.entidade.documento ? retorno.entidade.documento : new Documento();
      this._getFlag();
      this._specialist(retorno.entidade);
    } catch (error) {
      Functions.openDialog(this.dialog, error);
    }
  }
  private async _getFlag() {
    try {
      const retorno = await this._countryService.getFlag(
        this.destinySelected.id,
        true
      );
      this.destinySelected.bandeiraImg = retorno.entidade.bandeiraImg;
    } catch (error) {
      Functions.openDialog(this.dialog, error);
    }
  }
  private async _specialist(trecho: Destino) {
    trecho.idioma = this.idioma;
    this.trecho = trecho;
    try {
      const retorno = await this._user.especialistaTrecho(
        trecho
      );      
      this.specialists = retorno.lista;
    } catch (error) {
      console.log(error);
    }
  }

  //Origem
  private _initiCountryOri() {
    this.filteredOriCountrys.next(this.countrysOri.slice());
    this.countryOriFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filtercountrys();
      });
    this.setInitialValue();
  }
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
    this._onDestroyDes.next();
    this._onDestroyDes.complete();
  }
  protected setInitialValue() {
    this.filteredOriCountrys
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        if (this.singleSelect) {
          this.singleSelect.compareWith = (a: Pais, b: Pais) =>
            a && b && a.id === b.id;
        }
      });
  }

  protected filtercountrys() {
    if (!this.countrysOri) {
      return;
    }
    let search = this.countryOriFilterCtrl.value;
    if (!search) {
      this.filteredOriCountrys.next(this.countrysOri.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredOriCountrys.next(
      this.countrysOri.filter(
        (country) => country.nome.toLowerCase().indexOf(search) > -1
      )
    );
  }
  //Destino
  private _initiCountryDes() {
    this.filteredDesCountrys.next(this.countrysDest.slice());
    this.countryDesFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroyDes))
      .subscribe(() => {
        this.filterDesCountrys();
      });
    this.setInitialValueDes();
  }
  protected setInitialValueDes() {
    this.filteredDesCountrys
      .pipe(take(1), takeUntil(this._onDestroyDes))
      .subscribe(() => {
        if (this.singleSelectDes) {
          this.singleSelectDes.compareWith = (a: Pais, b: Pais) =>
            a && b && a.id === b.id;
        }
      });
  }
  protected filterDesCountrys() {
    if (!this.countrysDest) {
      return;
    }
    let search = this.countryDesFilterCtrl.value;
    if (!search) {
      this.filteredDesCountrys.next(this.countrysDest.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredDesCountrys.next(
      this.countrysDest.filter(
        (country) => country.nome.toLowerCase().indexOf(search) > -1
      )
    );
  }
}
