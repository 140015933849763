import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Retorno } from 'src/app/model/retorno';
import { AuthService } from '../auth/auth.service';
import { Pais } from 'src/app/model/pais';

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  private paisPath = '/pais';
  private bandeiraBath = '/pais/bandeira'
  constructor(private _auth: AuthService) { }

  getCounty(idioma: string, idExcluir?: number, hideLoad?: boolean): Promise<Retorno> {
    const idParam = idExcluir !== undefined ? idExcluir : '';
    let params = new HttpParams().set('idExcluir', `${idParam}`);
    return this._auth.getResource(`${this.paisPath}/${idioma}`, params, hideLoad);
  }
  update(pais: Pais): Promise<Retorno> {
    return this._auth.putResource(`${this.paisPath}`, pais);
  }
  getFlag(id: number, hideLoad?: boolean): Promise<Retorno> {
    return this._auth.getResource(`${this.bandeiraBath}/${id}`, null, hideLoad);
  }
}
