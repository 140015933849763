import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Retorno } from 'src/app/model/retorno';
import { AtendimentoEspecialistaSimples } from 'src/app/model/atendimento-especialista-simples';
import { HttpParams } from '@angular/common/http';
import { AtendimentoEspecialista } from 'src/app/model/atendimento-especialista';

@Injectable({
  providedIn: 'root',
})
export class AttendanceService {
  private atendimentoPath = '/atendimento';
  constructor(private _auth: AuthService) {}
  
  insert(attendance: AtendimentoEspecialistaSimples): Promise<Retorno> {
    return this._auth.postResource(`${this.atendimentoPath}`, attendance);
  }
  getFromSpecialist(id: number, page: number, page_size: number): Promise<Retorno> {
    let params = new HttpParams().set('page', `${page}`).set('page_size', `${page_size}`);
    return this._auth.getResource(`${this.atendimentoPath}/${id}`, params, true);
  }
  update(attendance: AtendimentoEspecialista): Promise<Retorno> {
    return this._auth.putResource(`${this.atendimentoPath}`, attendance);
  }
}
