<app-topo></app-topo>
<div class="container">
  <h1 class="line-background"><span>{{'recuperar_senha' | translate}}</span></h1>
  <div class="row">
    <div class="col-xl-3 .col-lg-3 .col-md-3 .col-sm-0 .col-0"></div>
    <div class="col-xl-6 .col-lg-6 .col-md-6 .col-sm-12 .col-12 frame">
      <form [formGroup]="formRec" #formDir="ngForm">
        <fieldset class="form-group">
          <label for="email" class="bmd-label-static">{{ "email" | translate }}*</label>
          <input type="email" id="email" class="form-control" formControlName="email"
            [(ngModel)]="userRec.email" [ngClass]="email.invalid && submitted ? 'danger' : ''" />
        </fieldset>
        <div class="row">
          <div class="col-sm"></div>
          <div class="col-sm right">
            <button type="submit" class="btn btn-primary" (click)="send()">
              {{'enviar' | translate}}
            </button>
          </div>
        </div>
      </form>
    </div>
    <div class="col-xl-3 .col-lg-3 .col-md-3 .col-sm-0 .col-0"></div>
  </div>
</div>
<app-rodape></app-rodape>