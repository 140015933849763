<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarNavAltMarkup"
    aria-controls="navbarNavAltMarkup"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
    <div class="navbar-nav">
      <a
        class="nav-link"
        href="#inicio"
        SimpleSmoothScroll
        [showHash]="true"
        >{{ "inicio" | translate }}
      </a>
      <a
        class="nav-link"
        href="#sobre"
        SimpleSmoothScroll
        [showHash]="true"
        >{{ "quem_somos" | translate }}
      </a>
      <a
        class="nav-link"
        href="#contato"
        SimpleSmoothScroll
        [showHash]="true"
        >{{ "contato" | translate }}
      </a>
    </div>
  </div>
  <button class="btn btn-link" (click)="login()">Login</button>
</nav>
