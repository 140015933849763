import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PerfilEnum } from 'src/app/model/enuns/perfil';
import { Usuario } from 'src/app/model/usuario';
import { UserService } from 'src/app/service/user/user.service';
import { AuthUtil } from 'src/app/utils/auth';
import { Functions } from 'src/app/utils/functions';

@Component({
  selector: 'app-edit-adm',
  templateUrl: './edit-adm.component.html',
  styleUrls: ['./edit-adm.component.scss']
})
export class EditAdmComponent implements OnInit {

  public user: Usuario;
  public podeAlterar = true;

  constructor(private _router: Router, private _userService: UserService, public dialog: MatDialog) {
    this.user = this._router.getCurrentNavigation().extras as Usuario;
  }

  ngOnInit(): void {
    this.podeAlterar = AuthUtil.getLogged() != this.user.email;
  }

  async update() {
    try {
      const retorno = await this._userService.update(this.user);
      Functions.openDialog(this.dialog, retorno, ()=>this._getUser());
    } catch (error) {
      Functions.openDialog(this.dialog, error);
    }
  }
  back(){
    this._router.navigate(['/painel/profiles'], PerfilEnum.ADMINISTRADOR as any);
  }
  private async _getUser() {
    try {
      const retorno = await this._userService.getUser(this.user.email);
      this.user = retorno.entidade;
    } catch (error) {
      Functions.openDialog(this.dialog, error);
    }
  }

}
