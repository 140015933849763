import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Login } from 'src/app/model/login';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { AuthService } from 'src/app/service/auth/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { Retorno } from 'src/app/model/retorno';
import { Functions } from 'src/app/utils/functions';
import { UserService } from 'src/app/service/user/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public login: Login;
  public formLogin;
  public submitted: boolean;
  public idioma: string = 'pt';
  public usuarioSenha: string;
  public camposObrigatorios: string;
  public usuarioLabel: string;
  public senhaLabel: string;

  constructor(
    private _router: Router,
    private _auth: AuthService,
    private _userService: UserService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.idioma = localStorage.getItem('idioma');
    this._idioma();
    this.login = new Login();
    this.formLogin = new FormGroup({
      usuario: new FormControl(this.login.usuario, [Validators.required]),
      senha: new FormControl(this.login.senha, [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(6),
      ]),
    });
  }
  async onLogin() {
    this.submitted = true;
    const description = [];
    if (this.login) {
      if (!this.login.usuario) {
        description.push(this.usuarioLabel);
      }
      if (!this.login.senha) {
        description.push(this.senhaLabel);
      }
    }
    if (description.length > 0) {
      this._error(description);
    } else {
      try {
        await this._auth.obtainAccessToken(this.login);
        this.login = new Login();
        try {
          await this._userService.getProfileAuth();
          this._router.navigateByUrl('/painel');
        } catch (error) {
          try {
            await this._userService.getProfileAuthSpecialist();
            this._router.navigateByUrl('/painel/specialist');
          } catch (error) {
            let retorno = new Retorno();
            retorno.mensagem = this.usuarioSenha;
            retorno.error = true;
            retorno.descricoes = description;
            Functions.openDialog(this.dialog, retorno);
          }
        }
      } catch (error) {
        let retorno = new Retorno();
        retorno.mensagem = this.usuarioSenha;
        retorno.error = true;
        retorno.descricoes = description;
        Functions.openDialog(this.dialog, retorno);
      }
    }
  }
  private _error(description) {
    let retorno = new Retorno();
    retorno.mensagem = this.camposObrigatorios;
    retorno.error = true;
    retorno.descricoes = description;
    Functions.openDialog(this.dialog, retorno);
  }
  get usuario() {
    return this.formLogin.get('usuario');
  }
  get senha() {
    return this.formLogin.get('senha');
  }
  private _idioma(){
    if(this.idioma === "en"){
      this.usuarioSenha = 'Invalid user and / or password (s)';
      this.camposObrigatorios = 'Required fields:';
      this.usuarioLabel = 'User';
      this.senhaLabel = 'Password';
    }else if(this.idioma === "es"){
      this.usuarioSenha = 'Usuario y / o contrase\u00f1a no válidos';
      this.camposObrigatorios = 'Campos obligatorios:';
      this.usuarioLabel = 'Usuario';
      this.senhaLabel = 'Contrase\u00f1a';
    }else{
      this.usuarioSenha = 'Usu\u00e1rio e/ou Senha inv\u00e1lido(s)';
      this.camposObrigatorios = 'Campos Obrigatórios:';
      this.usuarioLabel = 'Usuário';
      this.senhaLabel = 'Senha';
    }
  }
}
