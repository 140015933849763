export class Pais {
  id: number;
  sigla: string;
  nomePt: string;
  nomeEn: string;
  nomeEs: string;
  informacaoPt: string;
  informacaoEn: string;
  informacaoEs: string;
  nome: string;
  informacao: string;
  bandeiraImg: string;
}
