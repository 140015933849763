import { Pipe, PipeTransform } from '@angular/core';
import { Functions } from 'src/app/utils/functions';

@Pipe({
  name: 'cpf'
})
export class CpfPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    return Functions.createMaskCpf(value);
  }

}
