import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './page/home/home.component';
import { LoginComponent } from './page/login/login.component';
import { PasswordComponent } from './page/password/password.component';
import { MasterComponent } from './page/master/master.component';
import { UserComponent } from './page/user/user.component';
import { InformationComponent } from './page/information/information.component';
import { WelcomeComponent } from './page/welcome/welcome.component';
import { CountryComponent } from './page/country/country.component';
import { PaymentComponent } from './page/payment/payment.component';
import { RegisterComponent } from './page/register/register.component';
import { MasterSpecialistComponent } from './page/master-specialist/master-specialist.component';
import { StretchComponent } from './page/stretch/stretch.component';
import { ProfilesComponent } from './page/profiles/profiles.component';
import { EditAdmComponent } from './page/edit-adm/edit-adm.component';
import { EditSpecialistComponent } from './page/edit-specialist/edit-specialist.component';
import { ViewInterComponent } from './page/view-inter/view-inter.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'painel',
    children: [
      {
        path: 'login',
        component: LoginComponent,
      },
      {
        path: 'password_recovery',
        component: PasswordComponent,
      },
      {
        path: 'register',
        component: RegisterComponent,
      },
      {
        path: '',
        component: MasterComponent,
        children: [
          {
            path: '',
            component: WelcomeComponent,
          },
          {
            path: 'country',
            component: CountryComponent,
          },
          {
            path: 'information',
            component: InformationComponent,
          },
          {
            path: 'user',
            component: UserComponent,
          },
          {
            path: 'profiles',
            component: ProfilesComponent,
          },
          {
            path: 'edit_adm',
            component: EditAdmComponent,
          },
          {
            path: 'edit_specialist',
            component: EditSpecialistComponent,
          },
          {
            path: 'view_inter',
            component: ViewInterComponent,
          },
        ],
      },
      {
        path: '',
        component: MasterSpecialistComponent,
        children: [
          {
            path: '',
            component: RegisterComponent,
          },
          {
            path: 'strech',
            component: StretchComponent,
          },
        ],
      },
    ],
  },
  {
    path: 'painel/specialist',
    children: [
      {
        path: '',
        component: MasterSpecialistComponent,
        children: [
          {
            path: '',
            component: RegisterComponent,
          },
          {
            path: 'strech',
            component: StretchComponent,
          },
        ],
      },
      {
        path: 'payment',
        component: PaymentComponent
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
