import { Component, OnInit, ViewChild } from '@angular/core';
import { Pais } from 'src/app/model/pais';
import { CountryService } from 'src/app/service/country/country.service';
import { Functions } from 'src/app/utils/functions';
import { MatDialog } from '@angular/material/dialog';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { Subject, ReplaySubject } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { takeUntil, take } from 'rxjs/operators';

@Component({
  selector: 'app-country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.scss'],
})
export class CountryComponent implements OnInit {
  public pais;
  public countrys = [];
  public formPais;
  public submitted: boolean;
  protected _onDestroy = new Subject<void>();
  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
  public countryCtrl: FormControl = new FormControl();
  public countryFilterCtrl: FormControl = new FormControl();
  public filteredCountrys: ReplaySubject<Pais[]> = new ReplaySubject<Pais[]>(1);
  public selectedFile;
  constructor(
    private _countryService: CountryService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this._init();
    this._getCountys();
  }
  onClear() {
    this._init();
  }
  async save() {
    this.submitted = true;
    try {
      const retorno = await this._countryService.update(this.pais);
      Functions.openDialog(this.dialog, retorno, () => this.ngOnInit());
    } catch (error) {
      Functions.openDialog(this.dialog, error);
    }
  }
  onFileSelected(event) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.pais.bandeiraImg = reader.result;
    };
  }
  selectCountry(event){
    this._getFlag();
  }
  private _init() {
    this.submitted = false;
    this.pais = new Pais();
    this.formPais = new FormGroup({
      id: new FormControl(this.pais.id, [Validators.required]),
      sigla: new FormControl(this.pais.sigla, [Validators.required]),
      nomePt: new FormControl(this.pais.nomePt, [Validators.required]),
      nomeEn: new FormControl(this.pais.nomeEn, [Validators.required]),
      nomeEs: new FormControl(this.pais.nomeEs, [Validators.required]),
      informacaoPt: new FormControl(this.pais.informacaoPt, []),
      informacaoEn: new FormControl(this.pais.informacaoEn, []),
      informacaoEs: new FormControl(this.pais.informacaoEs, []),
    });
  }
  private async _getCountys() {
    try {
      const retorno = await this._countryService.getCounty('pt');
      this.countrys = retorno.lista;
      this._initiCountry();
    } catch (error) {
      Functions.openDialog(this.dialog, error);
    }
  }
  private async _getFlag(){
    try {
      const retorno = await this._countryService.getFlag(
        this.pais.id
      );
      this.pais.bandeiraImg = retorno.entidade.bandeiraImg;
    } catch (error) {
      Functions.openDialog(this.dialog, error);
    }
  }
  get id() {
    return this.formPais.get('id');
  }
  get sigla() {
    return this.formPais.get('sigla');
  }
  get nomePt() {
    return this.formPais.get('nomePt');
  }
  get nomeEn() {
    return this.formPais.get('nomeEn');
  }
  get nomeEs() {
    return this.formPais.get('nomeEs');
  }
  get informacaoPt() {
    return this.formPais.get('informacaoPt');
  }
  get informacaoEn() {
    return this.formPais.get('informacaoEn');
  }
  get informacaoEs() {
    return this.formPais.get('informacaoEs');
  }
  private _initiCountry() {
    this.filteredCountrys.next(this.countrys.slice());
    this.countryFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filtercountrys();
      });
    this.setInitialValue();
  }
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
  protected setInitialValue() {
    this.filteredCountrys
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        if (this.singleSelect) {
          this.singleSelect.compareWith = (a: Pais, b: Pais) =>
            a && b && a.id === b.id;
        }
      });
  }

  protected filtercountrys() {
    if (!this.countrys) {
      return;
    }
    let search = this.countryFilterCtrl.value;
    if (!search) {
      this.filteredCountrys.next(this.countrys.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredCountrys.next(
      this.countrys.filter(
        (country) => country.nome.toLowerCase().indexOf(search) > -1
      )
    );
  }
}
