import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/service/user/user.service';
import { Usuario } from 'src/app/model/usuario';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Functions } from 'src/app/utils/functions';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/service/auth/auth.service';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
})
export class PasswordComponent implements OnInit {
  public userRec: Usuario;
  public formRec;
  public submitted: boolean;
  public idioma: string;
  constructor(
    private router: Router,
    private _auth: AuthService,
    private _userService: UserService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.idioma = localStorage.getItem("idioma");
    this._init();
  }
  async send() {
    this.submitted = true;
    try {
      this.userRec.idioma = this.idioma;
      let retorno = await this._userService.updatePassoword(this.userRec);
      Functions.openDialog(this.dialog, retorno, () => this.toNavigate());
    } catch (error) {
      Functions.openDialog(this.dialog, error);
    }
  }
  toNavigate() {
    this.router.navigateByUrl('/painel/login');
  }
  private async _obtainToken() {
    await this._auth.obtainAccessToken(null);
  }
  private async _init() {
    await this._obtainToken();
    this.userRec = new Usuario();
    this.formRec = new FormGroup({
      email: new FormControl(this.userRec.email, [Validators.required]),
    });
  }
  get email() {
    return this.formRec.get('email');
  }
}
