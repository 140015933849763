<div class="container">
  <h1 class="line-background"><span>BEM VINDO</span></h1>
  <div class="row">
    <div class="col-xl-3 .col-lg-3 .col-md-3 .col-sm-0 .col-0"></div>
    <div class="col-xl-6 .col-lg-6 .col-md-6 .col-sm-12 .col-12 frame center">
      <h2>Acesse uma das opções do menu</h2>
    </div>
    <div class="col-xl-3 .col-lg-3 .col-md-3 .col-sm-0 .col-0"></div>
  </div>
</div>
