import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AtendimentoEspecialista } from 'src/app/model/atendimento-especialista';
import { PerfilEnum } from 'src/app/model/enuns/perfil';
import CelMask from 'src/app/model/mask/cel';
import CnpjMask from 'src/app/model/mask/cnpj';
import CodinMask from 'src/app/model/mask/codin';
import CpfMask from 'src/app/model/mask/cpf';
import PhoneMask from 'src/app/model/mask/phone';
import { Pais } from 'src/app/model/pais';
import { Usuario } from 'src/app/model/usuario';
import { AttendanceService } from 'src/app/service/attendance/attendance.service';
import { Functions } from 'src/app/utils/functions';

@Component({
  selector: 'app-edit-specialist',
  templateUrl: './edit-specialist.component.html',
  styleUrls: ['./edit-specialist.component.scss']
})
export class EditSpecialistComponent implements OnInit {

  public user: Usuario;
  public lista: AtendimentoEspecialista[];
  public pageNumber: number = 0;
  public pageSize: number = 5;
  public totalItens = 0;
  public paginas = 1;
  public selecionado = 0;

  public idioma: string = 'pt';
  public paisSelected: Pais;
  public celMask = new CelMask();
  public phoneMask = new PhoneMask();
  public cpfMask = new CpfMask();
  public cnpjMask = new CnpjMask();
  public codinMask = new CodinMask();
  public cpfField: string;
  public cnpjField: string;
  public phoneField: string;
  public celField: string;
  public preCelField: string;
  public prePhoneField: string;
  public disabled = true;
  public isCpf: boolean = true;
  public isBr: boolean;

  public combo = [{ value: true, name: "Ativo" },
  { value: false, name: "Inativo" }]

  constructor(private _router: Router, private _atdService: AttendanceService, public dialog: MatDialog) {
    this.user = this._router.getCurrentNavigation().extras as Usuario;
  }

  ngOnInit(): void {    
    this._formatUser();
    this._getAtdSpecilist();
  }

  back() {
    this._router.navigate(['/painel/profiles'], PerfilEnum.ESPECIALISTA as any);
  }
  navPage(page: number) {
    this.pageNumber = page - 1;
    this._getAtdSpecilist();
  }

  navPageInter(sentido: string) {

    if (sentido == 'a' && this.pageNumber > 0) {
      this.selecionado = 0;
      this.pageNumber = this.pageNumber - 1;
      this._getAtdSpecilist();
    } else if (sentido == 'p' && this.pageNumber < this.paginas - 1) {
      this.selecionado = 0;
      this.pageNumber = this.pageNumber + 1;
      this._getAtdSpecilist();
    }
  }

  async update(item: AtendimentoEspecialista) {
    try {
      this.selecionado = item.id;
      const retorno = await this._atdService.update(item);
      Functions.openDialog(this.dialog, retorno, () => this._getAtdSpecilist());
    } catch (error) {
      Functions.openDialog(this.dialog, error);
    }
  }

  private _formatUser() {
    this.cpfField = Functions.zeroLeft(this.user.cpf, 11);
    this.cnpjField = Functions.zeroLeft(this.user.cnpj, 14);
    this.preCelField = Functions.zeroLeft(this.user.preCel, 2);
    this.prePhoneField = Functions.zeroLeft(this.user.preFone, 2);
    this.phoneField = Functions.zeroLeft(this.user.telefone, 11);
    this.celField = Functions.zeroLeft(this.user.celular, 12);
    this.paisSelected = this.user.endereco.pais;
    this.isBr = this.paisSelected.sigla === 'BR';
  }

  private async _getAtdSpecilist() {
    try {
      const retorno = await this._atdService.getFromSpecialist(this.user.id, this.pageNumber, this.pageSize);
      this.lista = retorno.lista;
      this.totalItens = retorno.totalElementos;
      this.paginas = retorno.totalPaginas;
    } catch (error) {
      Functions.openDialog(this.dialog, error);
    }
  }
}
