<form [formGroup]="formEspecialista" #formDir="ngForm">
  <h1 class="line-background">
    <span>{{ "fale_especialista" | translate }}</span>
  </h1>
  <div class="row">
    <div class="col-sm">
      <div class="autocomplete">
        <div class="label autocomplete">{{ "especialista" | translate }}*</div>
      </div>
      <mat-form-field>
        <mat-select [(value)]="especialistaSelected" id="especialista" (selectionChange)="selectEspecialista($event)"
          formControlName="especialistaSelectedForm"
          [ngClass]="especialistaSelectedForm.invalid && submitted ? 'danger' : ''">
          <mat-option>
            <ngx-mat-select-search [placeholderLabel]="''" [noEntriesFoundLabel]="''"
              [formControl]="especialistaFilterCtrl">
            </ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let especialista of filteredEspecialistas | async" [value]="especialista">
            {{ especialista.nome }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <br />
  <div>{{"texto_dados_usuario" | translate}}</div>
  <br />
  <fieldset class="form-group">
    <label for="nome" class="bmd-label-static">{{ "nome" | translate }}*</label>
    <input type="text" class="form-control" id="nome" maxlength="45" [(ngModel)]="mensagem.remetente"
      formControlName="nome" [ngClass]="nome.invalid && submitted ? 'danger' : ''" />
  </fieldset>
  <fieldset class="form-group">
    <label for="email" class="bmd-label-static">{{ "email" | translate }}*</label>
    <input type="email" class="form-control" id="email" maxlength="45" [(ngModel)]="mensagem.email"
      formControlName="email" [ngClass]="email.invalid && submitted ? 'danger' : ''" />
  </fieldset>
  <div class="row">
    <div class="col-sm">
      <div class="autocomplete">
        <div class="label autocomplete">{{ "pais" | translate }}*</div>
      </div>
      <mat-form-field>
        <mat-select [(value)]="paisSelected" id="pais" (selectionChange)="selectCountry($event)"
          formControlName="countrySelectedForm" [ngClass]="countrySelectedForm.invalid && submitted ? 'danger' : ''">
          <mat-option>
            <ngx-mat-select-search [placeholderLabel]="''" [noEntriesFoundLabel]="''" [formControl]="countryFilterCtrl">
            </ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let country of filteredCountrys | async" [value]="country">
            {{ country.nome }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-sm">
      <fieldset class="form-group">
        <label for="estado" class="bmd-label-static">{{ "estado" | translate }}*</label>
        <input type="text" class="form-control" id="estado" maxlength="50" [(ngModel)]="mensagem.estadoRemetente"
          formControlName="estado" [ngClass]="estado.invalid && submitted ? 'danger' : ''" />
      </fieldset>
    </div>
  </div>
  <div class="row">
    <div class="col-sm">
      <fieldset class="form-group">
        <label for="cidade" class="bmd-label-static">{{ "cidade" | translate }}*</label>
        <input type="text" class="form-control" id="cidade" maxlength="50" [(ngModel)]="mensagem.cidadeRemetente"
          formControlName="cidade" [ngClass]="cidade.invalid && submitted ? 'danger' : ''" />
      </fieldset>
    </div>
    <div class="col-sm">
      <div class="row">
        <div class="col-sm-4">
          <fieldset class="form-group">
            <label for="preFone" class="bmd-label-static">{{
              "codigo" | translate
            }}*</label>
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">+</span>
              </div>
              <input type="text" class="form-control" id="preFone" [(ngModel)]="prePhoneField" [textMask]="codinMask"
                formControlName="preFone" [ngClass]="preFone.invalid && submitted ? 'danger' : ''" />
            </div>
          </fieldset>
        </div>
        <div class="col-sm-8">
          <fieldset class="form-group">
            <label for="tel" class="bmd-label-static">{{
              "tel" | translate
            }}*</label>
            <input type="text" class="form-control" id="tel" formControlName="telRemetente" [(ngModel)]="phoneField"
              formControlName="telRemetente" [textMask]="celMask"
              [ngClass]="telRemetente.invalid && submitted ? 'danger' : ''" />
          </fieldset>
        </div>
      </div>
    </div>
  </div>
  <fieldset class="form-group">
    <label for="texto" class="bmd-label-static">{{
      "mensagem" | translate
    }}*</label>
    <textarea class="form-control" id="texto" [(ngModel)]="mensagem.mensagem" formControlName="texto" maxlength="1000"
      [ngClass]="texto.invalid && submitted ? 'danger' : ''" rows="3"></textarea>
  </fieldset>
</form>
<div class="row">
  <div class="col-sm left botton"></div>
  <div class="col-sm right">
    <button type="submit" class="btn btn-primary" (click)="enviar()">
      {{ "enviar" | translate }}
    </button>
  </div>
</div>