<div class="topo-line"></div>
<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarNavAltMarkup"
    aria-controls="navbarNavAltMarkup"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
    <div class="navbar-nav">
      <a
        class="nav-item nav-link"
        routerLink="/painel/specialist"
        data-toggle="collapse"
        data-target=".navbar-collapse.show"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        >{{ "dados" | translate }}
      </a>
      <a
        class="nav-item nav-link"
        routerLink="/painel/strech"
        data-toggle="collapse"
        data-target=".navbar-collapse.show"
        routerLinkActive="active"
        >{{ "novo_atendimento" | translate }}
      </a>
    </div>
  </div>
  <button class="btn btn-link" (click)="onLogout()">Logout</button>
</nav>
<app-title></app-title>
<router-outlet></router-outlet>
<app-rodape></app-rodape>
