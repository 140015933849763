export class Retorno {
  mensagem: string;
  descricoes: string[];
  entidade: any;
  lista: any;
  error: boolean;
  totalPaginas: number;
  pagina: number;
  totalElementos: number;
}
