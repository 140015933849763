import { Pais } from './pais';
import { Documento } from './documento';

export class Destino {
  id: number;
  origem: Pais;
  destino: Pais;
  idioma: string;
  documento: Documento = new Documento();
}
