import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Documento } from 'src/app/model/documento';
import { Retorno } from 'src/app/model/retorno';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  private documentPath = '/documento';
  constructor(private _auth: AuthService) {}

  insert(document: Documento): Promise<Retorno> {
    return this._auth.postResource(`${this.documentPath}`, document);
  }
  update(document: Documento): Promise<Retorno> {
    return this._auth.putResource(`${this.documentPath}`, document);
  }
}
