import { Pipe, PipeTransform } from '@angular/core';
import { Functions } from 'src/app/utils/functions';

@Pipe({
  name: 'cnpj'
})
export class CnpjPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    return Functions.createMaskCnpj(value);
  }

}
