<div mat-dialog-title>
  <div class="middle">
    <span class="material-icons success" *ngIf="!data.error">check_circle</span>
    <span class="material-icons danger" *ngIf="data.error">
      error
    </span>
    <span class="title">{{ data.mensagem }}</span>
  </div>
  <div class="descricao" *ngIf="data.error">
    <li *ngFor="let descricao of data.descricoes">{{descricao}}</li>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button
    type="button"
    class="btn btn-primary"
    [mat-dialog-close]=""
    cdkFocusInitial
  >
    OK
  </button>
</div>
