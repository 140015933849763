import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import * as editorConfig from '../../../assets/json/editor-config.json';
import { StretchService } from 'src/app/service/stretch/stretch.service';
import { CountryService } from 'src/app/service/country/country.service';
import { MatDialog } from '@angular/material/dialog';
import { Functions } from 'src/app/utils/functions';
import { Pais } from 'src/app/model/pais';
import { Documento } from 'src/app/model/documento';
import { Destino } from 'src/app/model/destino';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { DocumentService } from 'src/app/service/document/document.service';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss'],
})
export class InformationComponent implements OnInit {
  protected _onDestroy = new Subject<void>();
  protected _onDestroyDes = new Subject<void>();
  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
  @ViewChild('singleSelect', { static: true }) singleSelectDes: MatSelect;

  public originSelected: Pais;
  public destinySelected: Pais;
  public document: Documento;
  public editorConfig: AngularEditorConfig;
  public countrysOri: Pais[];
  public countrysDest: Pais[];
  public formDocument;
  public submitted;

  //Origem
  public countryOriCtrl: FormControl = new FormControl();
  public countryOriFilterCtrl: FormControl = new FormControl();
  public filteredOriCountrys: ReplaySubject<Pais[]> = new ReplaySubject<Pais[]>(
    1
  );

  //Destino
  public countryDesCtrl: FormControl = new FormControl();
  public countryDesFilterCtrl: FormControl = new FormControl();
  public filteredDesCountrys: ReplaySubject<Pais[]> = new ReplaySubject<Pais[]>(
    1
  );

  constructor(
    private _countryService: CountryService,
    private _stretch: StretchService,
    private _documentService: DocumentService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.editorConfig = (editorConfig as any).default;
    this._init();
  }
  selectOrigin(event) {
    this.document = new Documento();
    this.document.destino = new Destino();
    this.document.destino.origem = this.originSelected;
    if (this.originSelected) {
      this._getCountys(this.originSelected.id);
    } else {
      this.destinySelected = null;
    }
  }
  selectDestiny(event) {
    if (this.originSelected && this.destinySelected) {
      this._getStretch();
    }
  }
  async save() {
    this.submitted = true;
    try {
      let retorno;
      if (this.document.id) {
        retorno = await this._documentService.update(this.document);
      } else {
        retorno = await this._documentService.insert(this.document);
      }
      Functions.openDialog(this.dialog, retorno, () => this.ngOnInit());
    } catch (error) {
      Functions.openDialog(this.dialog, error);
    }
  }
  private _init() {
    this.submitted = false;
    this.originSelected = null;
    this.destinySelected = null;
    this.countrysOri = [];
    this.countrysDest = null;
    this.document = new Documento();
    this._form();
    this._getCountys();
  }
  private _form() {
    this.formDocument = new FormGroup({
      originSelectedForm: new FormControl(this.originSelected, [
        Validators.required,
      ]),
      destinySelectedForm: new FormControl(this.destinySelected, [
        Validators.required,
      ]),
      descTurismoPt: new FormControl(this.document.descTurismoPt, []),
      descResiTempPt: new FormControl(this.document.descResiTempPt, []),
      descResiFixaPt: new FormControl(this.document.descResiFixaPt, []),
      embaixadaPt: new FormControl(this.document.embaixadaPt, []),
      descTurismoEn: new FormControl(this.document.descTurismoEn, []),
      descResiTempEn: new FormControl(this.document.descResiTempEn, []),
      descResiFixaEn: new FormControl(this.document.descResiFixaEn, []),
      embaixadaEn: new FormControl(this.document.embaixadaEn, []),
      descTurismoEs: new FormControl(this.document.descTurismoEs, []),
      descResiTempEs: new FormControl(this.document.descResiTempEs, []),
      descResiFixaEs: new FormControl(this.document.descResiFixaEs, []),
      embaixadaEs: new FormControl(this.document.embaixadaEs, []),
    });
  }
  private async _getCountys(id?: number) {
    try {
      const retorno = await this._countryService.getCounty('pt', id);
      if (id) {
        this.countrysDest = retorno.lista;
        this._initiCountryDes();
      } else {
        this.countrysOri = retorno.lista;
        this._initiCountryOri();
      }
    } catch (error) {
      Functions.openDialog(this.dialog, error);
    }
  }
  private async _getStretch() {
    try {
      const retorno = await this._stretch.getStretch(
        this.originSelected.id,
        this.destinySelected.id
      );
      this.document = retorno.entidade.documento;
      this.document.destino = new Destino();
      this.document.destino.origem = this.originSelected;
      this.document.destino.destino = this.destinySelected;
    } catch (error) {
      Functions.openDialog(this.dialog, error);
    }
  }
  get originSelectedForm() {
    return this.formDocument.get('originSelectedForm');
  }
  get destinySelectedForm() {
    return this.formDocument.get('destinySelectedForm');
  }
  get descTurismoPt() {
    return this.formDocument.get('descTurismoPt');
  }
  get descResiTempPt() {
    return this.formDocument.get('descResiTempPt');
  }
  get descResiFixaPt() {
    return this.formDocument.get('descResiFixaPt');
  }
  get embaixadaPt() {
    return this.formDocument.get('embaixadaPt');
  }
  get descTurismoEn() {
    return this.formDocument.get('descTurismoEn');
  }
  get descResiTempEn() {
    return this.formDocument.get('descResiTempEn');
  }
  get descResiFixaEn() {
    return this.formDocument.get('descResiFixaEn');
  }
  get embaixadaEn() {
    return this.formDocument.get('embaixadaEn');
  }
  get descTurismoEs() {
    return this.formDocument.get('descTurismoEs');
  }
  get descResiTempEs() {
    return this.formDocument.get('descResiTempEs');
  }
  get descResiFixaEs() {
    return this.formDocument.get('descResiFixaEs');
  }
  get embaixadaEs() {
    return this.formDocument.get('embaixadaEs');
  }
  //Origem
  private _initiCountryOri() {
    this.filteredOriCountrys.next(this.countrysOri.slice());
    this.countryOriFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filtercountrys();
      });
    this.setInitialValue();
  }
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
    this._onDestroyDes.next();
    this._onDestroyDes.complete();
  }
  protected setInitialValue() {
    this.filteredOriCountrys
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        if (this.singleSelect) {
          this.singleSelect.compareWith = (a: Pais, b: Pais) =>
            a && b && a.id === b.id;
        }
      });
  }

  protected filtercountrys() {
    if (!this.countrysOri) {
      return;
    }
    let search = this.countryOriFilterCtrl.value;
    if (!search) {
      this.filteredOriCountrys.next(this.countrysOri.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredOriCountrys.next(
      this.countrysOri.filter(
        (country) => country.nome.toLowerCase().indexOf(search) > -1
      )
    );
  }
  //Destino
  private _initiCountryDes() {
    this.filteredDesCountrys.next(this.countrysDest.slice());
    this.countryDesFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroyDes))
      .subscribe(() => {
        this.filterDesCountrys();
      });
    this.setInitialValueDes();
  }
  protected setInitialValueDes() {
    this.filteredDesCountrys
      .pipe(take(1), takeUntil(this._onDestroyDes))
      .subscribe(() => {
        if (this.singleSelectDes) {
          this.singleSelectDes.compareWith = (a: Pais, b: Pais) =>
            a && b && a.id === b.id;
        }
      });
  }
  protected filterDesCountrys() {
    if (!this.countrysDest) {
      return;
    }
    let search = this.countryDesFilterCtrl.value;
    if (!search) {
      this.filteredDesCountrys.next(this.countrysDest.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredDesCountrys.next(
      this.countrysDest.filter(
        (country) => country.nome.toLowerCase().indexOf(search) > -1
      )
    );
  }
}
