import { Endereco } from './endereco';

export class Usuario {
  id: number;
  nome: string;
  email: string;
  senha: string;
  repetirSenha: string;
  profissao: string;
  ativo: boolean;
  endereco: Endereco;
  cpf: number;
  cnpj: number;
  telefone: number;
  celular: number;
  perfil: number;
  brasileiro: boolean;
  regInternacional: string;
  preFone: number;
  preCel: number;
  idioma: string;
}
