import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './page/home/home.component';
import { LoginComponent } from './page/login/login.component';
import { TopoComponent } from './component/topo/topo.component';
import { RodapeComponent } from './component/rodape/rodape.component';
import { PasswordComponent } from './page/password/password.component';
import { MasterComponent } from './page/master/master.component';
import { UserComponent } from './page/user/user.component';
import { InformationComponent } from './page/information/information.component';
import { WelcomeComponent } from './page/welcome/welcome.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TitleComponent } from './component/title/title.component';
import { CountryComponent } from './page/country/country.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTabsModule } from '@angular/material/tabs';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { MatSelectModule } from '@angular/material/select';
import { DialogComponent } from './component/dialog/dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { LoadComponent } from './component/load/load.component';
import { MenuComponent } from './component/menu/menu.component';
import { TopmenuComponent } from './component/topmenu/topmenu.component';
import { InformationHomeComponent } from './component/information-home/information-home.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { PaymentComponent } from './page/payment/payment.component';
import { RegisterComponent } from './page/register/register.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PhoneCelPipe } from './pipe/cel/phone-cel.pipe';
import { PhonePipe } from './pipe/phone/phone.pipe';
import { CpfPipe } from './pipe/cpf/cpf.pipe';
import { CnpjPipe } from './pipe/cnpj/cnpj.pipe';
import { RodapePainelComponent } from './component/rodape-painel/rodape-painel.component';
import { ContactComponent } from './component/contact/contact.component';
import { UsComponent } from './component/us/us.component';
import { SimpleSmoothScrollModule } from 'ng2-simple-smooth-scroll';
import { SpecialistComponent } from './component/specialist/specialist.component';
import { MasterSpecialistComponent } from './page/master-specialist/master-specialist.component';
import { StretchComponent } from './page/stretch/stretch.component';
import { TextMaskModule } from 'angular2-text-mask';
import { ProfilesComponent } from './page/profiles/profiles.component';
import { EditAdmComponent } from './page/edit-adm/edit-adm.component';
import { EditSpecialistComponent } from './page/edit-specialist/edit-specialist.component';
import { ViewInterComponent } from './page/view-inter/view-inter.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ZerosPipe } from './pipe/zeros/zeros.pipe';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomParserFormatter } from './utils/ngb-date-custom-parser-formatter';

export function TranslationLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    TopoComponent,
    RodapeComponent,
    PasswordComponent,
    MasterComponent,
    UserComponent,
    InformationComponent,
    WelcomeComponent,
    TitleComponent,
    CountryComponent,
    DialogComponent,
    LoadComponent,
    MenuComponent,
    TopmenuComponent,
    InformationHomeComponent,
    PaymentComponent,
    RegisterComponent,
    PhoneCelPipe,
    PhonePipe,
    CpfPipe,
    CnpjPipe,
    RodapePainelComponent,
    ContactComponent,
    UsComponent,
    SpecialistComponent,
    StretchComponent,
    MasterSpecialistComponent,
    ProfilesComponent,
    EditAdmComponent,
    EditSpecialistComponent,
    ViewInterComponent,
    ZerosPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    AutocompleteLibModule,
    MatTabsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AngularEditorModule,
    MatSelectModule,
    MatDialogModule,
    NgxMatSelectSearchModule,
    SimpleSmoothScrollModule,
    TextMaskModule,
    MatDatepickerModule,
    NgbModule,
    TranslateModule.forRoot({
      loader: { provide: TranslateLoader, useFactory: TranslationLoaderFactory, deps: [HttpClient] }
    })
  ],
  exports: [
    MatDatepickerModule
  ],
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
