<app-topmenu></app-topmenu>
<div class="container">
  <div class="bottom">
    <app-information-home id="inicio"></app-information-home>
  </div>
  <div class="bottom">
    <app-us id="sobre"></app-us>
  </div>
  <div class="bottom">
    <app-contact id="contato"></app-contact>
  </div>
</div>
<app-rodape></app-rodape>
