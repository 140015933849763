<app-topo *ngIf="!disabled"></app-topo>
<div class="container">
    <h1 class="line-background" *ngIf="!disabled"><span>{{'registro' | translate}}</span></h1>
    <h1 class="line-background" *ngIf="disabled"><span>{{'dados' | translate}}</span></h1>
    <form [formGroup]="formReg" #formDir="ngForm">
        <fieldset class="form-group">
            <label for="nome" class="bmd-label-static">{{'nome' | translate}}*</label>
            <input type="text" class="form-control" id="nome" maxlength="45" [(ngModel)]="user.nome"
                formControlName="nome" [ngClass]="nome.invalid && submitted ? 'danger' : ''" [readonly]="disabled">
        </fieldset>
        <fieldset class="form-group">
            <label for="email" class="bmd-label-static">{{'email' | translate}}*</label>
            <input type="email" class="form-control" id="email" maxlength="45" [(ngModel)]="user.email"
                formControlName="email" [ngClass]="email.invalid && submitted ? 'danger' : ''" [readonly]="disabled">
        </fieldset>
        <div class="row">
            <div class="col-sm">
                <fieldset class="form-group">
                    <label for="senha" class="bmd-label-static">{{'senha' | translate}}*</label>
                    <input type="password" class="form-control" id="senha" maxlength="6" [(ngModel)]="user.senha"
                        formControlName="senha" [ngClass]="senha.invalid && submitted ? 'danger' : ''">
                </fieldset>
            </div>
            <div class="col-sm">
                <fieldset class="form-group">
                    <label for="repeti_senha" class="bmd-label-static">{{'repeti_senha' | translate}}*</label>
                    <input type="password" class="form-control" id="email" maxlength="6" [(ngModel)]="user.repetirSenha"
                        formControlName="repetirSenha" [ngClass]="repetirSenha.invalid && submitted ? 'danger' : ''">
                </fieldset>
            </div>
        </div>
        <div class="row">
            <div class="col-sm">
                <div *ngIf="!disabled">
                    <div class="autocomplete">
                        <div class="label autocomplete">{{'pais' | translate}}*</div>
                    </div>
                    <mat-form-field>
                        <mat-select [(value)]="paisSelected" id="pais" (selectionChange)="selectCountry($event)"
                            formControlName="countrySelectedForm"
                            [ngClass]="countrySelectedForm.invalid && submitted ? 'danger' : ''">
                            <mat-option>
                                <ngx-mat-select-search [placeholderLabel]="''" [noEntriesFoundLabel]="''"
                                    [formControl]="countryFilterCtrl"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let country of filteredCountrys | async" [value]="country">
                                {{ country.nome }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="disabled">
                    <fieldset class="form-group">
                        <label for="paisLabel" class="bmd-label-static">{{'pais' | translate}}*</label>
                        <input type="text" class="form-control" id="paisLabel" [readonly]="disabled"
                            [(ngModel)]="paisSelected.nome" formControlName="countrySelectedForm"
                            [ngClass]="countrySelectedForm.invalid && submitted ? 'danger' : ''">
                    </fieldset>
                </div>
            </div>
            <div class="col-sm">
                <fieldset class="form-group">
                    <label for="estado" class="bmd-label-static">{{'estado' | translate}}*</label>
                    <input type="text" class="form-control" id="estado" maxlength="50"
                        [(ngModel)]="user.endereco.estado" formControlName="estado"
                        [ngClass]="estado.invalid && submitted ? 'danger' : ''">
                </fieldset>
            </div>
        </div>
        <span *ngIf="isBr">
            <div *ngIf="disabled">
                <fieldset *ngIf="isCpf" class="form-group">
                    <label for="cpfText" class="bmd-label-static">CPF*</label>
                    <input type="text" class="form-control" id="cpfText" formControlName="cpf" [(ngModel)]="cpfField"
                        [textMask]="cpfMask" [ngClass]="cpf.invalid && submitted ? 'danger' : ''">
                </fieldset>
                <fieldset *ngIf="!isCpf" class="form-group">
                    <label for="cnpjText" class="bmd-label-static">CNPJ*</label>
                    <input type="text" class="form-control" id="cnpjText" formControlName="cnpj" [(ngModel)]="cnpjField"
                        [textMask]="cnpjMask" [ngClass]="cnpj.invalid && submitted ? 'danger' : ''">
                </fieldset>
            </div>
            <div *ngIf="!disabled">
                <label class="radio-inline">
                    <input type="radio" [(ngModel)]="isCpf" formControlName="cpfSelecionado" name="cpfSelecionado"
                        id="cpf" [value]="true"> CPF
                </label>&nbsp;
                <label class="radio-inline">
                    <input type="radio" [(ngModel)]="isCpf" formControlName="cnpjSelecionado" name="cnpjSelecionado"
                        id="cnpj" [value]="false"> CNPJ
                </label>
                <fieldset *ngIf="isCpf" class="form-group">
                    <input type="text" class="form-control" id="cpfText" formControlName="cpf" [(ngModel)]="cpfField"
                        [textMask]="cpfMask" [ngClass]="cpf.invalid && submitted ? 'danger' : ''">
                </fieldset>
                <fieldset *ngIf="!isCpf" class="form-group">
                    <input type="text" class="form-control" id="cnpjText" formControlName="cnpj" [(ngModel)]="cnpjField"
                        [textMask]="cnpjMask" [ngClass]="cnpj.invalid && submitted ? 'danger' : ''">
                </fieldset>
            </div>
        </span>
        <span *ngIf="!isBr">
            <fieldset class="form-group">
                <label for="regint" class="bmd-label-static">{{'regint' | translate}}*</label>
                <input type="text" class="form-control" id="regint" maxlength="50" [(ngModel)]="user.regInternacional"
                    formControlName="regInternacional"
                    [ngClass]="regInternacional.invalid && submitted ? 'danger' : ''">
            </fieldset>
        </span>
        <fieldset class="form-group">
            <label for="endereco" class="bmd-label-static">{{'endereco' | translate}}*</label>
            <input type="text" class="form-control" id="endereco" maxlength="500" [(ngModel)]="user.endereco.endereco"
                formControlName="endereco" [ngClass]="endereco.invalid && submitted ? 'danger' : ''">
        </fieldset>
        <div class="row">
            <div class="col-sm">
                <fieldset class="form-group">
                    <label for="numero" class="bmd-label-static">{{'numero' | translate}}*</label>
                    <input type="text" class="form-control" id="numero" maxlength="10"
                        [(ngModel)]="user.endereco.numero" formControlName="numero"
                        [ngClass]="numero.invalid && submitted ? 'danger' : ''">
                </fieldset>
            </div>
            <div class="col-sm">
                <fieldset class="form-group">
                    <label for="complemento" class="bmd-label-static">{{'complemento' | translate}}</label>
                    <input type="text" class="form-control" id="complemento" maxlength="200"
                        [(ngModel)]="user.endereco.complemento" formControlName="complemento"
                        [ngClass]="complemento.invalid && submitted ? 'danger' : ''">
                </fieldset>
            </div>
        </div>
        <div class="row">
            <div class="col-sm">
                <fieldset class="form-group">
                    <label for="bairro" class="bmd-label-static">{{'bairro' | translate}}*</label>
                    <input type="text" class="form-control" id="bairro" maxlength="50"
                        [(ngModel)]="user.endereco.bairro" formControlName="bairro"
                        [ngClass]="bairro.invalid && submitted ? 'danger' : ''">
                </fieldset>
            </div>
            <div class="col-sm">
                <fieldset class="form-group">
                    <label for="cidade" class="bmd-label-static">{{'cidade' | translate}}*</label>
                    <input type="text" class="form-control" id="cidade" maxlength="50"
                        [(ngModel)]="user.endereco.cidade" formControlName="cidade"
                        [ngClass]="cidade.invalid && submitted ? 'danger' : ''">
                </fieldset>
            </div>
        </div>
        <div class="row">
            <div class="col-sm">
                <div class="row">
                    <div class="col-sm-4">
                        <fieldset class="form-group">
                            <label for="preTel" class="bmd-label-static">{{'codigo' | translate}}</label>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1">+</span>
                                </div>
                                <input type="text" class="form-control" id="preTel" [(ngModel)]="prePhoneField"
                                    formControlName="preFone" [textMask]="codinMask"
                                    [ngClass]="preFone.invalid && submitted ? 'danger' : ''">
                            </div>
                        </fieldset>
                    </div>
                    <div class="col-sm-8">
                        <fieldset class="form-group">
                            <label for="tel" class="bmd-label-static">{{'tel' | translate}}</label>
                            <input type="text" class="form-control" id="tel" formControlName="telefone"
                                [(ngModel)]="phoneField" formControlName="telefone" [textMask]="phoneMask"
                                [ngClass]="telefone.invalid && submitted ? 'danger' : ''">
                        </fieldset>
                    </div>
                </div>
            </div>
            <div class="col-sm">
                <div class="row">
                    <div class="col-sm-4">
                        <fieldset class="form-group">
                            <label for="preCel" class="bmd-label-static">{{'codigo' | translate}}</label>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1">+</span>
                                </div>
                                <input type="text" class="form-control" id="preCel" [(ngModel)]="preCelField"
                                    formControlName="preCel" [textMask]="codinMask"
                                    [ngClass]="preCel.invalid && submitted ? 'danger' : ''">
                            </div>
                        </fieldset>
                    </div>
                    <div class="col-sm-8">
                        <fieldset class="form-group">
                            <label for="cel" class="bmd-label-static">{{'cel' | translate}}</label>
                            <input type="text" class="form-control" id="cel" [(ngModel)]="celField"
                                formControlName="celular" [textMask]="celMask"
                                [ngClass]="celular.invalid && submitted ? 'danger' : ''">
                        </fieldset>
                    </div>
                </div>

            </div>
        </div>
        <fieldset class="form-group">
            <label for="profissao" class="bmd-label-static">{{'profissao' | translate}}</label>
            <input type="text" class="form-control" id="profissao" [(ngModel)]="user.profissao" maxlength="45"
                formControlName="profissao" [ngClass]="profissao.invalid && submitted ? 'danger' : ''">
        </fieldset>
    </form>
    <div class="row">
        <div class="col-sm left botton"></div>
        <div class="col-sm right">
            <button type="submit" class="btn btn-primary" (click)="register()">
                {{'salvar' | translate}}
            </button>
        </div>
    </div>
</div>
<app-rodape *ngIf="!disabled"></app-rodape>