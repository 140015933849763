import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Mensagem } from 'src/app/model/mensagem';
import { MatDialog } from '@angular/material/dialog';
import { CountryService } from 'src/app/service/country/country.service';
import { Pais } from 'src/app/model/pais';
import { Subject, ReplaySubject } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Functions } from 'src/app/utils/functions';
import { takeUntil, take } from 'rxjs/operators';
import { Usuario } from 'src/app/model/usuario';
import CodinMask from 'src/app/model/mask/codin';
import { Destino } from 'src/app/model/destino';
import CelMask from 'src/app/model/mask/cel';
import { MessageService } from 'src/app/service/message/message.service';

@Component({
  selector: 'app-specialist',
  templateUrl: './specialist.component.html',
  styleUrls: ['./specialist.component.scss'],
})
export class SpecialistComponent implements OnInit {
  @Input() public specialists: Usuario[];
  @Input() public trecho: Destino;

  public idioma: string = 'pt';
  public mensagem: Mensagem;
  public paisSelected: Pais;
  public especialistaSelected: Usuario;
  public countrys: Pais[];
  public formEspecialista;
  public submitted;

  protected _onDestroy = new Subject<void>();
  protected _onDestroyEsp = new Subject<void>();
  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
  @ViewChild('singleSelect', { static: true }) singleSelectEspe: MatSelect;
  public countryCtrl: FormControl = new FormControl();
  public countryFilterCtrl: FormControl = new FormControl();
  public filteredCountrys: ReplaySubject<Pais[]> = new ReplaySubject<Pais[]>(1);

  public especialistaCtrl: FormControl = new FormControl();
  public especialistaFilterCtrl: FormControl = new FormControl();
  public filteredEspecialistas: ReplaySubject<Usuario[]> = new ReplaySubject<
    Usuario[]
  >(1);

  public celMask = new CelMask();
  public codinMask = new CodinMask();
  public phoneField: string;
  public prePhoneField: string;
  public defaultValue: Usuario;
  private deafultLabel: string = "Selecione um Especialista";

  constructor(
    private _countryService: CountryService,
    private _messageService: MessageService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.idioma = localStorage.getItem('idioma');
    this._prepareMsg();
    this._form();     
    if(this.specialists.length > 1){
      this.specialists = Functions.randomArray(this.specialists);
      this._idiomaDefaultLabel();
      this.defaultValue = new Usuario();
      this.defaultValue.nome = this.deafultLabel;
      this.specialists.unshift(this.defaultValue);
    }
    this.paisSelected = null;
    this.especialistaSelected = null;
    this._initiEspecialista();
    this._init();
    this.submitted = false;
  }
  selectCountry(event) {
    if (this.paisSelected) {
      this.mensagem.pais = this.paisSelected;
    }
  }
  selectEspecialista(event) {
    if (this.especialistaSelected && this.especialistaSelected.id) {
      this.mensagem.destinatario = this.especialistaSelected;
    }else{
      this.especialistaSelected = null;
      this.mensagem.destinatario = this.especialistaSelected;
      this.formEspecialista
      .get('especialistaSelectedForm')
      .setValue(this.especialistaSelected);
    }
  }
  async enviar() {
    this.submitted = true;
    this.mensagem.trecho = this.trecho;
    this.mensagem.idioma = this.idioma;
    this.mensagem.preFone = Functions.number(this.prePhoneField);
    this.mensagem.telRemetente = Functions.number(this.phoneField);
    try {
      const retorno = await this._messageService.sender(this.mensagem);
      Functions.openDialog(this.dialog, retorno, () => this.ngOnInit());
    } catch (error) {
      Functions.openDialog(this.dialog, error);
    }
  }
  private async _init() {
    await this._getCountys();
  }
  private async _getCountys() {
    try {
      const retorno = await this._countryService.getCounty(
        this.idioma,
        0,
        true
      );
      this.countrys = retorno.lista;
      this._initiCountry();
    } catch (error) {
      Functions.openDialog(this.dialog, error);
    }
  }
  private _initiCountry() {
    this.filteredCountrys.next(this.countrys.slice());
    this.countryFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCountrys();
      });
    this.setInitialValue();
  }
  protected filterCountrys() {
    if (!this.countrys) {
      return;
    }
    let search = this.countryFilterCtrl.value;
    if (!search) {
      this.filteredCountrys.next(this.countrys.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredCountrys.next(
      this.countrys.filter(
        (country) => country.nome.toLowerCase().indexOf(search) > -1
      )
    );
  }
  protected setInitialValue() {
    this.filteredCountrys
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        if (this.singleSelect) {
          this.singleSelect.compareWith = (a: Pais, b: Pais) =>
            a && b && a.id === b.id;
        }
      });
  }
  //especialista
  private _initiEspecialista() {
    this.filteredEspecialistas.next(this.specialists.slice());
    this.especialistaFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroyEsp))
      .subscribe(() => {
        this.filterEspec();
      });
    this.setInitialValueEspec();
    this._inicialSelect();
  }
  protected filterEspec() {
    if (!this.specialists) {
      return;
    }
    let search = this.especialistaFilterCtrl.value;
    if (!search) {
      this.filteredEspecialistas.next(this.specialists.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredEspecialistas.next(
      this.specialists.filter(
        (espec) => espec.nome.toLowerCase().indexOf(search) > -1
      )
    );
  }
  protected setInitialValueEspec() {
    this.filteredEspecialistas
      .pipe(take(1), takeUntil(this._onDestroyEsp))
      .subscribe(() => {
        if (this.singleSelect) {
          this.singleSelect.compareWith = (a: Usuario, b: Usuario) =>
            a && b && a.id === b.id;
        }
      });
  }
  private _form() {
    this.formEspecialista = new FormGroup({
      countrySelectedForm: new FormControl(this.paisSelected, [
        Validators.required,
      ]),
      especialistaSelectedForm: new FormControl(this.especialistaSelected, [
        Validators.required,
      ]),
      nome: new FormControl(this.mensagem.remetente, [Validators.required]),
      email: new FormControl(this.mensagem.email, [Validators.required]),
      cidade: new FormControl(this.mensagem.cidadeRemetente, [
        Validators.required,
      ]),
      estado: new FormControl(this.mensagem.estadoRemetente, [
        Validators.required,
      ]),
      destinatario: new FormControl(this.mensagem.destinatario, [
        Validators.required,
      ]),
      texto: new FormControl(this.mensagem.mensagem, [Validators.required]),
      preFone: new FormControl(this.mensagem.preFone, [Validators.required]),
      telRemetente: new FormControl(this.mensagem.telRemetente, [
        Validators.required,
      ]),
    });
  }
  private _inicialSelect() {
    if (this.specialists.length === 1) {
      this.especialistaSelected = this.specialists[0];
      this.formEspecialista
        .get('especialistaSelectedForm')
        .setValue(this.especialistaSelected);
      this.selectEspecialista(null);
    }else{
      this.formEspecialista
      .get('especialistaSelectedForm')
      .setValue(this.defaultValue);
      this.especialistaSelected = null;
    }
  }
  private _prepareMsg() {
    this.mensagem = new Mensagem();
  }
  private _idiomaDefaultLabel(){
    if(this.idioma === "en"){
      this.deafultLabel = 'Select an Specialist';
    }else if(this.idioma === "es"){
      this.deafultLabel = 'Seleccionar un Especilista';
    }else{
      this.deafultLabel = 'Selecione um Especialista';
    }
  }
  get countrySelectedForm() {
    return this.formEspecialista.get('countrySelectedForm');
  }
  get especialistaSelectedForm() {
    return this.formEspecialista.get('especialistaSelectedForm');
  }
  get nome() {
    return this.formEspecialista.get('nome');
  }
  get email() {
    return this.formEspecialista.get('email');
  }
  get cidade() {
    return this.formEspecialista.get('cidade');
  }
  get estado() {
    return this.formEspecialista.get('estado');
  }
  get destinatario() {
    return this.formEspecialista.get('destinatario');
  }
  get texto() {
    return this.formEspecialista.get('texto');
  }
  get preFone() {
    return this.formEspecialista.get('preFone');
  }
  get telRemetente() {
    return this.formEspecialista.get('telRemetente');
  }
}
