<div class="footer white">
  <ul>
    <div (click)="useLanguage('pt')" class="idioma">{{'portugues' | translate }}</div>
  </ul>
  <ul>
    <div (click)="useLanguage('en')" class="idioma">{{'ingles' | translate }}</div>
  </ul>
  <ul>
    <div (click)="useLanguage('es')" class="idioma" click>{{'espanhol'  | translate }}</div>
  </ul>
  <div class="right">
    <img class="material-icons" src="../../../assets/copyright-white-18dp.svg" />2020 - {{data | date:'yyyy'}}. {{'copy'  | translate }}
  </div>
</div>